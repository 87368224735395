<template>
  <v-container fluid>

    <v-row>
      <v-col align="center">
        <UniverseComponent/>
      </v-col>
    </v-row>

    <v-row>
      <v-col  cols="12" align="center">
        <v-simple-table>
          <thead>
          <tr>
            <th class="text-left">Universe</th>
            <th class="text-left">Name</th>
            <th class="text-left">Tag</th>
            <th class="text-left">Text tag</th>
            <th class="text-left">Interviews</th>
            <th class="text-left">Users</th>
            <th class="text-left">Open period</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="dto in surveyDataList" :key="dto.id" @click="onSurveyClick(dto)">
            <td>{{dto.universeName}}</td>
            <td>{{dto.name}}</td>
            <td>{{dto.tag}}</td>
            <td>{{dto.textTag}}</td>
            <td>{{dto.nInterviews}}</td>
            <td>{{dto.nUsers}}</td>
            <td>{{dto.samplesOpen}}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="center">
        <RolesList/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ScriptBlockList global-script/>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="center">
        <v-simple-table>
          <thead>
          <tr>
            <th class="text-left">API Key</th>
            <th class="text-left">Type</th>
            <th class="text-left">Parameter</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="dto in apiKeyList" :key="dto.id">
            <td><v-text-field readonly v-model="dto.extname"></v-text-field></td>
            <td><v-select :items="apiKeyTypes"   v-model="dto.type"></v-select></td>
            <td><v-text-field v-model="dto.path"></v-text-field></td>

            <v-btn @click="onApiSave(dto)" icon>
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn @click="onApiDelete(dto)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </tr>
          </tbody>
        </v-simple-table>

        <div class="d-flex">
          <v-btn @click="onApiCreate(dto)" icon>
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>


      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import SurveysService from "@/services/SurveysService";
import PageNavigator from "@/pagenavigator";
import RolesList from "@/views/backend/RolesList";
import ScriptBlockList from "@/views/backend/ScriptBlockList";
import UniverseComponent from "@/views/backend/UniverseComponent";

export default {
  name: 'SurveyList',

  data () {
    return {
      surveyDataList: [],
      apiKeyList: [],
    }
  },

  components: {
    UniverseComponent,
    ScriptBlockList,
    RolesList
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'userIdentity']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey']),

    apiKeyTypes() {
      return [
        { text: "CSI Widget", value:1 },
        { text: "Google Widget", value:2 },
        { text: "API Access", value:3 },
        { text: "Big Screen", value:4 }
      ]
    },

  },

  methods: {
    initialFetchData() {
      SurveysService.fetchSurveyListData(this.getAuthToken, this.activeSurvey.extName).then((response) => {
        this.surveyDataList = response.data.surveyRows;
        this.apiKeyList = response.data.apiKeyRows;
      })
    },

    onSurveyClick(dto) {
      this.$store.commit('selectSurvey', dto.name)
      PageNavigator.navigateToSurveyDetailsPage(this, this.activeSurvey.extName, dto.id)
    },

    onApiSave(dto) {
      SurveysService.saveApiKey(this.getAuthToken, this.activeSurvey.extName, dto).then(() => {
        this.initialFetchData();
      })
    },

    onApiCreate() {
      SurveysService.createApiKey(this.getAuthToken, this.activeSurvey.extName).then((/*response*/) => {
        this.initialFetchData();
      })
    },

    onApiDelete(dto) {
      SurveysService.deleteApiKey(this.getAuthToken, this.activeSurvey.extName, dto.id).then((/*response*/) => {
        this.initialFetchData();
      })
    }

  },



  watch: {

    $route: {
      handler() {
        this.initialFetchData();
      },
      immediate: true
    }
  }


}
</script>

