<template>
  <div>
    <v-autocomplete auto-select-first @input="autoModel= ''" v-if="showTypeahead" @change="onAutoComplete" :items="uncheckedOptionObjects" item-text="title" item-value="extName" v-model="autoModel" :delimiters="[';']" />
    <v-list dense>
      <v-list-item dense v-for="option in listedOptions" :key="option.extName">
        <v-list-item-content>
          <v-checkbox dense hide-details class="filterCheckBox ma-0 pa-0" :label="option.title" v-model="checkedOptions" :value="option.extName" @change="checkClicked"></v-checkbox>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import {mapState} from "vuex";
import _ from 'lodash'

export default {
  name: 'FilterComponentChecks',

  data () {
    return {
      autoModel: [],
      checkedOptions: []
    }
  },

  props: {
    filter: Object
  },

  computed: {
    ...mapState(['combinedFilters']),

    showTypeahead() {
      return this.filter.options.length>=10
    },

    listedOptions() {
      if(this.showTypeahead) {
        return this.checkedOptionObjects
      }

      return this.filter.options
    },

    checkedOptionObjects() {
      return this.filter.options.filter(o => this.checkedOptions.includes(o.extName)  )
    },

    uncheckedOptionObjects() {
      return this.filter.options.filter(o => !this.checkedOptions.includes(o.extName) )
    }


  },

  created() {
    this.checkedOptions = []
  },

  methods: {
    onAutoComplete(o) {
      this.checkedOptions.push(o)
      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: this.checkedOptions})
      this.autoModel = []
    },

    checkClicked() {
      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: this.checkedOptions})
    }
  },


  watch: {
    combinedFilters: {
      handler() {
        // console.log("Checkbuttons: " + this.filter.key + " detected change of combinedfilters")

        let filterValues = this.combinedFilters.getFilterValuesForFilter(this.filter.extName);

        // console.log("We have: " + this.checkedOptions + " and they:" + filterValues )

        if(!_.isEqual(filterValues, this.checkedOptions)) {
          // console.log("Assigned: " + filterValues + " to: " + this.filter.extName + " as we had: " + this.checkedOptions + "type:" + typeof(filterValues) + " type2: " + typeof(this.checkedOptions))
          this.checkedOptions = [ ...filterValues]
        }

        // this.autoModel = []
      },
      deep: true,
      immediate: true
    },

  },


}
</script>

<style>
.filterCheckBox {
  /*    color: red; */
}

div.filterCheckBox div.v-input__control div.v-input__slot {
  margin: 0 !important;
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5/16)*1rem) !important;

}

div.filterCheckBox label {
  left: 6px !important;
}

div.filterCheckBox label {
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5/16)*1rem) !important;

}

.v-select-list .v-list .v-input__slot { margin-bottom: 0 !important; }

/*.v-autocomplete-content .v-select-list .v-subheader {*/
/*  color: blue;*/
/*  font-size: 13.5px !important;*/
/*}*/

</style>
