var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"wrapper elevation-2",attrs:{"fluid":""}},[(_vm.showExtraMenu)?_c('div',{staticClass:"menuwrapper"},[_c('v-menu',{staticClass:"float-right",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","dark":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1237447417)},[_c('v-list',_vm._l((_vm.extraVariables),function(extraVar,idx){return _c('v-list-item',{key:idx},[_c('v-list-item-title',{staticClass:"mx-0",on:{"click":function($event){return _vm.onExtraSelected(extraVar)}}},[_vm._v(_vm._s(extraVar.title))])],1)}),1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"title "},on),[_vm._v(" "+_vm._s(_vm.currentVariable.title)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.currentVariable.helpText))])])],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"score",style:({ color: _vm.titleColor })},[_vm._v(" "+_vm._s(_vm.currentVariable.formattedValue)+" ")])])],1),_c('v-row',{staticClass:"mt-0",style:({ 'visibility': _vm.progressBarVisibility })},[_c('v-col',{staticClass:"py-0"},[_c('AnimatedProgressBarComponent',{attrs:{"color":_vm.titleColor,"value":_vm.valuePercentage,"target":_vm.goalPercentage}})],1)],1),_c('v-row',{attrs:{"align":"center mb-0"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"iconnumberleft"},on),[_c('v-icon',{staticClass:"bottomicon",attrs:{"large":"","color":_vm.arrowColor}},[_vm._v(_vm._s(_vm.arrowIcon))]),_c('span',{staticClass:"bottomNumbers"},[_vm._v(_vm._s(_vm.currentVariable.formattedDiff))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("i18n")('DIFF_TOOLTIP')))])])],1),_c('v-col',{attrs:{"cols":"6"}},[(!_vm.vardto.hideProgressBar)?_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"iconnumberright"},on),[_c('v-icon',{staticClass:"bottomicon mr-3",attrs:{"small":""}},[_vm._v("mdi-crosshairs-gps")]),_c('span',{staticClass:"bottomNumbers"},[_vm._v(_vm._s(_vm.currentVariable.formattedGoal))])],1)]}}],null,false,942267215)},[_c('span',[_vm._v(_vm._s(_vm._f("i18n")('GOAL_TOOLTIP')))])])],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }