import backend from '@/services/backend'

export default {

  fetchInterviewData (authToken, surveyName, interviewId) {
//    console.log('fetchInterviewData: ' + authToken + ' id:' + interviewId)
    return backend.get('vuejs/interview/' + surveyName + '/' + interviewId, { headers: { Authorization: authToken } })
  },

  shareInterview (authToken, surveyName, interviewId, shareType) {
    // console.log('shareInterview: ' + surveyName + ' id:' + interviewId)
    return backend.get('vuejs/sharing/interview/' + surveyName + '/' + interviewId + '/' + shareType, { headers: { Authorization: authToken } })
  },



}
