import AnalyticsService from "./services/AnalyticsService";
import store from './store'

export default {

  navigateToDefaultPage(source, activeSurvey) {
    if(activeSurvey.pages.length>0) {
      this.navigateToPage(source, activeSurvey.pages[0].extname, activeSurvey.extName, activeSurvey.startPath)
    }
  },

  navigateToPage(source, pageExtname, surveyExtName, path) {
    const router = source.$router

    AnalyticsService.trackNavigation(source, pageExtname)

    if(pageExtname === 'rankings') {
      router.push({ name: 'rankings', params: { surveyExtName: surveyExtName, drillPath: path } })
    } else if(pageExtname === 'alerts') {
      router.push({ name: 'alerts', params: { surveyExtName: surveyExtName, drillPath: path } })
    } else if(pageExtname === 'samples') {
      router.push({ name: 'samples', params: { surveyExtName: surveyExtName, drillPath: path } })
    } else {
      router.push({ name: 'page', params: { pageId: pageExtname, surveyExtName: surveyExtName, drillPath: path } })
    }
  },

  navigateToRankingsPage(source, surveyExtName, path) {
    const pageId = store.getters.getRankingPageId
    this.navigateToPage(source, pageId, surveyExtName, path)
  },

  navigateToAlertsPage(source, surveyExtName, path) {
    this.navigateToPage(source, 'alerts', surveyExtName, path)
  },

  navigateToAlertPage(source, surveyExtName, alertId) {
    AnalyticsService.trackNavigation(source, 'alert')
    source.$router.push({ name: 'alert', params: { surveyExtName: surveyExtName, alertId: alertId } })
  },

  navigateToSamplesPage(source, surveyExtName, path) {
    this.navigateToPage(source, 'samples', surveyExtName, path)
  },

  navigateToLoginPage(source) {
    const router = source.$router
    router.push({ name: 'login' })
  },

  navigateToLoginPageChangeUniverse(source) {
    const router = source.$router
    router.push({ name: 'login', query: { change: null } })
  },


  navigateToForgotPage(source) {
    AnalyticsService.trackNavigation(source, 'forgot')
    const router = source.$router
    router.push({ name: 'forgot' })
  },

  navigateToForwardVGSPage(source) {
    const router = source.$router
    router.push({ name: 'vgs' })
  },

  navigateToForwardFinlandPage(source) {
    const router = source.$router
    router.push({ name: 'finland' })
  },

  navigateToInterviewPage(source, surveyExtName, interviewId) {
    AnalyticsService.trackNavigation(source, 'interview')
    const router = source.$router
    router.push( { name: 'interview', params: { surveyExtName: surveyExtName, interviewId: interviewId } } )
  },

  navigateToSamplePage(source, surveyExtName, pageName, sampleId) {
    AnalyticsService.trackNavigation(source, 'sample')
    const router = source.$router
    router.push( { name: 'sample', params: { surveyExtName: surveyExtName, fromPage: pageName, sampleId: sampleId } } )
  },

  navigateToSurveyListPage(source, surveyExtName) {
    const router = source.$router
    router.push( { name: 'surveylist', params: { surveyExtName: surveyExtName } } )
  },

  navigateToSurveyDetailsPage(source, surveyExtName, surveyId) {
    const router = source.$router
    router.push( { name: 'survey', params: { surveyExtName: surveyExtName, surveyId: surveyId } } )
  },

  navigateToScriptDetailsPage(source, surveyExtName, surveyId, scriptId) {
    const router = source.$router
    router.push( { name: 'script', params: { surveyExtName: surveyExtName, surveyId: surveyId, scriptId: scriptId } } )
  },

  navigateToGlobalScriptDetailsPage(source, surveyExtName, scriptId) {
    const router = source.$router
    router.push( { name: 'globalscript', params: { surveyExtName: surveyExtName, scriptId: scriptId } } )
  },


  navigateToRoleDetailsPage(source, surveyExtName, roleId) {
    const router = source.$router
    router.push( { name: 'role', params: { surveyExtName: surveyExtName, roleId: roleId } } )
  },



  navigateToMailTemplateDetailsPage(source, surveyExtName, mailTemplateName, fromPage) {
    const router = source.$router
    router.push( { name: 'mailtemplate', params: { surveyExtName: surveyExtName, name: mailTemplateName }, query: {fromPage: fromPage} } )
  },

  navigateToMailCampaignDetailsPage(source, surveyExtName, campaignId, fromPage) {
    const router = source.$router
    router.push( { name: 'mailcampaign', params: { surveyExtName: surveyExtName, campaignId: campaignId }, query: {fromPage: fromPage} } )
  },

  navigateToCreateMailCampaignPage(source, surveyExtName, drillPath, mailAction, fromPage) {
    const router = source.$router
    router.push( { name: 'createmailcampaign', params: { surveyExtName: surveyExtName, drillPath: drillPath, mailAction : mailAction }, query: {fromPage: fromPage} } )
  },


  navigateToMailCampaignOverviewPage(source, surveyExtName, path) {
    const pageId = store.getters.getMailCampaignOverviewPageId
    this.navigateToPage(source, pageId, surveyExtName, path)
  },

  navigateToMailCampaignOverviewPageWithCampaignId(source, surveyExtName, path, campaignId) {
    const pageId = store.getters.getMailCampaignOverviewPageId
    const router = source.$router
    router.push({ name: 'page', params: { pageId: pageId, surveyExtName: surveyExtName, drillPath: path }, query: {campaignId: campaignId} })
  },


}
