<template>
  <div style="width: 100%">
    <GlobalUserHeader :global-overview-data="liveglobalOverviewData" @change="onTimeChange"></GlobalUserHeader>

    <v-container fluid>
      <v-row no-gutters>
        <v-col>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <IndicatorBlockComponent v-for="(blockdata, idx) in liveglobalOverviewData.indicatorBlocks"
                                   :block-data="blockdata" :loading="loading" :key="idx"
                                   @select="(universeMetaInterview) => $emit('select', universeMetaInterview)"/>
        </v-col>
      </v-row>

    </v-container>

  </div>

</template>

<script>
import {mapGetters} from "vuex";
import ResultsService from "@/services/ResultsService";
import IndicatorBlockComponent from "@/views/login/IndicatorBlockComponent";
import GlobalUserHeader from "@/views/login/GlobalUserHeader";

export default {
  name: 'GlobalOverviewComponent',

  components: {
    GlobalUserHeader,
    IndicatorBlockComponent
  },

  data () {
    return {
      updatedglobalOverviewData: null,
      loading: false
    }
  },

  props: {
    username: String,
    globalOverviewData: Object
  },

  computed: {
    liveglobalOverviewData() {
      return this.updatedglobalOverviewData!==null ? this.updatedglobalOverviewData : this.globalOverviewData
    },

    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'isUserRoot']),
  },

  methods: {
    onTimeChange(timespan) {
      this.loadData(timespan)
    },

    loadData(timespan) {
      this.loading = true
      ResultsService.fetchGlobalOverviewData(this.getAuthToken, this.username, timespan).then((response) => {
        this.loading = false
        this.updatedglobalOverviewData = response.data
      })

    },


  }

}
</script>
<style scoped>
</style>
