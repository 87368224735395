<template>
  <div>
    <template v-if="!chartOptions">
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </template>
    <template v-else>
      <highcharts :options="chartOptions" :ref="'chart'"></highcharts>
    </template>
<!--    selected: {{selectedCategory}}-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Chart} from 'highcharts-vue'

import ResultsService from "../../services/ResultsService";
import ChartUtil from "../../chartutil";

export default {
  name: 'GroupedChartComponent',

  components: {
    highcharts: Chart
  },

  data () {
    return {
      chartOptions: null,
      selectedCategory: null
    }
  },


  props: {
    componentData: Object
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])
  },

  methods: {
    loadChartData() {
      const id = (this.componentData.config) ? this.componentData.config.id : this.componentData.id

      ResultsService.fetchGroupedChartData(this.getAuthToken, this.getActiveSurvey.extName, id, this.getFilteredDrillPath).then((response) => {
        this.updateChart(response.data)
      })
    },

    updateChart(chartdata) {
      if (! chartdata.lang) {
        chartdata.lang = ChartUtil.getLangTranslations(this) // TODO [tho] - move this to ChartsPort
      }
      if (! chartdata.exporting) {
        chartdata.exporting = ChartUtil.getExportingSettings() // TODO [tho] - move this to ChartsPort
      }

      const self = this

      chartdata.plotOptions.series.events = {
        click: function (event) {
          self.setSelectedCategoryIndex(event.point.index)
        }
      }

      this.chartOptions = chartdata;
    },

    setSelectedCategoryIndex(categoryIndex) {
      const categoryPath = this.chartOptions.categoryPaths[categoryIndex]
      const selectedCategoryName =  categoryPath.name

      for(const [, tick] of Object.entries(this.$refs.chart.chart.xAxis[0].ticks)) {
        if(tick.label) {
          tick.label.css({
            fontWeight: 'normal'
          });
        }
      }

      if(this.selectedCategory === selectedCategoryName) { // deselect
        this.selectedCategory = null;

        const extname = (this.componentData.config) ? this.componentData.config.extname : this.componentData.extname

        if(extname) {
          this.$store.dispatch('postGlobalEvent', { source: extname, type: "filterPath", data: null  })
        }

      } else {
        this.selectedCategory = selectedCategoryName
        const tick = this.$refs.chart.chart.xAxis[0].ticks[categoryIndex];

        tick.label.css({
          fontWeight: '1000'
        });

        const extname = (this.componentData.config) ? this.componentData.config.extname : this.componentData.extname

        if(extname) {
          this.$store.dispatch('postGlobalEvent', { source: extname, type: "filterPath", data: categoryPath  })
        }

      }


    }

  },

}
</script>
<style scoped>
</style>
