import backend from '@/services/backend'

export default {


  // saveApiKey(authToken, surveyName, apiKeyDTO) {
  //   return backend({ method: 'post', url: 'vuejs/surveys/apikey/' + surveyName, data: apiKeyDTO,  headers: { Authorization: authToken } })
  // },
  //
  // createApiKey(authToken, surveyName) {
  //   return backend.get('vuejs/surveys/apikey/create/' + surveyName, { headers: { Authorization: authToken } })
  // },
  //
  // deleteApiKey(authToken, surveyName, apiKeyId) {
  //   return backend.get('vuejs/surveys/apikey/delete/' + surveyName + '/' + apiKeyId, { headers: { Authorization: authToken } })
  // },
  //
  // fetchMetaData(authToken, surveyName, surveyId) {
  //   return backend.get('vuejs/surveys/metadata/'+ surveyName + '/' + surveyId, { headers: { Authorization: authToken } })
  // },
  //
  fetchScriptBlocks(authToken, surveyName, surveyId) {
    return backend.get('vuejs/scripts/'+ surveyName + '/' + surveyId, { headers: { Authorization: authToken } })
  },

  fetchGlobalScriptBlocks(authToken) {
    return backend.get('vuejs/scripts/globals', { headers: { Authorization: authToken } })
  },

  fetchScriptBlock(authToken, surveyName, scriptId) {
    return backend.get('vuejs/scripts/details/'+ surveyName + '/' + scriptId, { headers: { Authorization: authToken } })
  },

  saveScriptBlock(authToken, surveyName, scriptBlock) {
    return backend({ method: 'post', url: 'vuejs/scripts/' + surveyName, data: scriptBlock,  headers: { Authorization: authToken } })
  },

  createScriptBlock(authToken, surveyName, surveyId) {
    return backend.get('vuejs/scripts/create/'+ surveyName + '/' + surveyId, { headers: { Authorization: authToken } })
  },

  createGlobalScriptBlock(authToken) {
    return backend.get('vuejs/scripts/create', { headers: { Authorization: authToken } })
  },

  deleteScriptBlock(authToken, surveyName, scriptId) {
    return backend.delete('vuejs/scripts/delete/'+ surveyName + '/' + scriptId, { headers: { Authorization: authToken } })
  },

  runScriptBlock(authToken, surveyName, surveyId, scriptId, dryrun) {
    return backend.get('vuejs/scripts/run/'+ surveyName + '/' + surveyId + '/' + scriptId + '/' + dryrun, { headers: { Authorization: authToken } })
  },

    //
  // createConfigBlock(authToken, surveyName, surveyId) {
  //   return backend.get('vuejs/surveys/configblocks/create/'+ surveyName + '/' + surveyId, { headers: { Authorization: authToken } })
  // },
  //
  // saveMetaProperty(authToken, surveyName, mdpdata) {
  //   return backend({ method: 'post', url: 'vuejs/surveys/metaproperty/' + surveyName, data: mdpdata,  headers: { Authorization: authToken } })
  // },
  //
  //
  // saveConfigBlock(authToken, surveyName, cblock) {
  //   return backend({ method: 'post', url: 'vuejs/surveys/configblocks/' + surveyName, data: cblock,  headers: { Authorization: authToken } })
  // },
  //
  // //////////// effectblocks ////////////
  // fetchEffectBlocks(authToken, surveyName, surveyId) {
  //   return backend.get('vuejs/surveys/effectblocks/'+ surveyName + '/'+ surveyId, { headers: { Authorization: authToken } })
  // },
  //
  //
  // saveEffectBlock(authToken, surveyName, blockdata) {
  //   return backend({ method: 'post', url: 'vuejs/surveys/effectblocks/' + surveyName, data: blockdata,  headers: { Authorization: authToken } })
  // },
  //
  // createEffectBlock(authToken, surveyName, surveyId) {
  //   return backend.get('vuejs/surveys/effectblocks/create/'+ surveyName + '/'+ surveyId, { headers: { Authorization: authToken } })
  // },
  //
  // deleteEffectBlock(authToken, surveyName, surveyId, blockId) {
  //   return backend.delete('vuejs/surveys/effectblocks/'+ surveyName + '/'+ surveyId + '/' + blockId, { headers: { Authorization: authToken } })
  // }


}
