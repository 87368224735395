import backend from '@/services/backend'
import store from '@/store'

export default {

  /**
   * @param username
   * @param password
   */
  authenticateUserPassword (username, password, remember, universe, surveyName, actAsUser, actAsUniverse) {
//    console.log('authenticateUserPassword: ' + username + ' / ' + password + ' / ' + remember)
    return backend({ method: 'post', url: 'vuejs/auth/login', data: { username, password, remember, universe, surveyName, actAsUser, actAsUniverse } })
  },

  authenticateCookie (sessionId) {
//    console.log('authenticateCookie: ' + sessionId)
    return backend({ method: 'post', url: 'vuejs/auth/validatecookie', data: { sessionId } })
  },

  changeUniverse (authToken, universeName) {
//    console.log('changeUniverse: ' + universeName)
    return backend.get('vuejs/auth/changeuniverse/' + universeName, { headers: { Authorization: 'Bearer ' + authToken } })
  },

  requestResetPasswordLink (email) {
    return backend.get('vuejs/auth/forgot/' + email)
  },

  resetPassword (token, password) {
    return backend({ method: 'post', url: 'vuejs/auth/reset', data: { token, password } })
  },

  logout () {
    store.commit('logout')
  }

}
