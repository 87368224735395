<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col cols="12" align="center">

        <div class="headline">Downloading, please wait...</div>
        <div class="title">We are processing your request.</div>

        <v-progress-circular v-if="!isFinished" :size="100" :width="4" color="#0000c8">
          {{ value }}
        </v-progress-circular>

        <p if="position">Your report is number {{position}} in the queue.</p>

        <p v-if="!isFinished">When your report is generated you can download it here.</p>
        <p v-else><a target="_blank" :href="downloadLink">Download</a></p>

        <!--<p>Your report is ready!</p>-->


      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import ReportsService from "../../services/ReportsService";

export default {
  name: 'ReportDownload',

  data () {
    return {
      downloadStatus: null,

      position: null,
      value: '',
    }
  },

  components: {
    // FilterBarComponent, RankingTable
  },

  created() {
    this.startReportPolling()
  },

  destroyed() {
    this.stopReportPolling()
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'userIdentity']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey']),

    isFinished() {
      return this.downloadStatus!==null && this.downloadStatus.status==='FINISHED'
    },

    downloadLink() {
      // http://localhost:8080/dashboard/service/download?uid=14078&t=task&reportid=4930f5c4-afd5-4627-a3b0-39ef046e4299
      // http://localhost:8080/dashboard/service/download?uid=14078&t=task&reportid=e7501e0b-8e45-425e-9819-e99474a230be
      //        const link = "http://localhost:8080/dashboard/service/download?" + "uid=" + this.userIdentity.userId + "&t=task" + "&reportid=" + this.downloadStatus.taskUUID

      // const params = "uid=" + this.userIdentity.userId + "&t=task" + "&reportid=" + this.downloadStatus.taskUUID;

      const link = "dummy" //ReportsService.overviewReportDownloadURL(this.getAuthToken, params)

      return link
    }


  },


  methods: {
    startReportPolling() {
      this.interval = setInterval(this.pollReportStatus, 2000)
    },

    stopReportPolling() {
      clearInterval(this.interval)
    },

    pollReportStatus() {
      const uuid = this.$route.params.reportUUID

      ReportsService.getReportStatus(this.getAuthToken, uuid).then((response) => {
        const result = response.data

        this.value = result.position
        this.position = result.position

        // console.log("POS: " + result.position)

        this.downloadStatus = result;

        if(result.status==='FINISHED') {
          this.stopReportPolling()
        }

      })

    },

  },

}
</script>
