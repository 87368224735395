import backend from '@/services/backend'

export default {

  fetchNewRankingData (authToken, surveyName, configId, drillPath, filterTerm) {
//    console.log('fetchRankingData: ' + authToken + ' dp:' + drillPath + "/" + filterTerm)
    return backend.get('vuejs/newranking/list/' + surveyName + '/' + configId + '/' + drillPath + '/' + filterTerm, { headers: { Authorization: authToken } })
  },

  fetchNewRankingDataPaged (authToken, surveyName, configId, drillPath, fromRow, toRow, sortVar, sortDesc, filterTerm) {
    // console.log('fetchRankingDataPaged: ' + authToken + ' dp:' + drillPath + " from:" + fromRow + " to:" + toRow + " sortVar:" + sortVar + " sortDesc:" + sortDesc + '/' + filterTerm)
    return backend.get('vuejs/newranking/list/' + surveyName + '/' + configId + '/' + drillPath + '/' + fromRow + '/' + toRow + '/' + sortVar + '/' + sortDesc + '/' + filterTerm, { headers: { Authorization: authToken } })
  },

  fetchNewRankingSuggestions(authToken, surveyName, drillPath, searchTerm) {
//    console.log('fetchRankingSuggestions: ' + authToken + ' dp:' + drillPath + " searchterm: " + searchTerm)
    return backend.get('vuejs/newranking/suggestions/' + surveyName + '/' + drillPath + '/' + searchTerm, { headers: { Authorization: authToken } })
  },








  fetchRankingData (authToken, surveyName, drillPath, filterTerm) {
//    console.log('fetchRankingData: ' + authToken + ' dp:' + drillPath + "/" + filterTerm)
    return backend.get('vuejs/ranking/list/' + surveyName + '/' + drillPath + '/' + filterTerm, { headers: { Authorization: authToken } })
  },

  fetchRankingDataPaged (authToken, surveyName, drillPath, fromRow, toRow, sortVar, sortDesc, filterTerm) {
//    console.log('fetchRankingDataPaged: ' + authToken + ' dp:' + drillPath + " from:" + fromRow + " to:" + toRow + " sortVar:" + sortVar + " sortDesc:" + sortDesc + '/' + filterTerm)
    return backend.get('vuejs/ranking/list/' + surveyName + '/' + drillPath + '/' + fromRow + '/' + toRow + '/' + sortVar + '/' + sortDesc + '/' + filterTerm, { headers: { Authorization: authToken } })
  },

  fetchRankingSuggestions(authToken, surveyName, drillPath, searchTerm) {
//    console.log('fetchRankingSuggestions: ' + authToken + ' dp:' + drillPath + " searchterm: " + searchTerm)
    return backend.get('vuejs/ranking/suggestions/' + surveyName + '/' + drillPath + '/' + searchTerm, { headers: { Authorization: authToken } })
  },

  fetchDevChartData (authToken, surveyName, chartId, period, drillPath) {
    // console.log('fetchDevChartData: ' + authToken + ' chartId:' + chartId + ' period:' + period + ' dp:' + drillPath)
    return backend.get('vuejs/charts/chart/' + surveyName + '/' + chartId + '/' + period + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchGroupedChartData(authToken, surveyName, chartId, drillPath) {
    return this.fetchDevChartData(authToken, surveyName, chartId, "dummy", drillPath)
  },

  fetchActionDevChartData (authToken, surveyName, drillPath, expression) {
    return backend({ method: 'post', url: 'vuejs/charts/actionchart/' + surveyName + '/' + drillPath, data: expression,  headers: { Authorization: authToken } })
  },

  fetchVariableBlockData (authToken, surveyName, configId, drillPath) {
    // console.log('fetchVariableBlockData: ' + authToken + ' configId:' + configId + ' dp:' + drillPath)
    return backend.get('vuejs/variables/block/' + surveyName + '/' + configId + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchVariableChart (authToken, surveyName, configId, drillPath, index) {
    // console.log('fetchVariableChart')
    return backend.get('vuejs/variables/fetchVariableChart/' + surveyName + '/' + configId + '/' + drillPath + '/' + index, { headers: { Authorization: authToken } })
  },

  fetchGlobalOverviewData (authToken, username, timespan) {
    return backend.get('vuejs/indicators/overview/' + username + '/' + timespan, {headers: {Authorization: authToken}})
  },

  fetchIndicatorBlockData(authToken, configId, drillPath) {
    return backend.get('vuejs/indicators/block/' + configId + '/' + drillPath, {headers: {Authorization: authToken}})
  },

  fetchActionPlanData (authToken, surveyName, configId, drillPath) {
    // console.log('fetchActionPlanData: ' + authToken + ' configId:' + configId + ' dp:' + drillPath)
    return backend.get('vuejs/actions/actionplan/' + surveyName + '/' + configId + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchGamificationData (authToken, surveyName, configId, drillPath) {
    return backend.get('vuejs/gamification/overview/' + surveyName + '/' + configId + '/' + drillPath, { headers: { Authorization: authToken } })
  },


  fetchActionManagerData (authToken, surveyName, configId, drillPath) {
    return backend.get('vuejs/actions/actionmanager/' + surveyName + '/' + configId + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  getActionData (authToken, surveyName, actionId) {
    return backend.get('vuejs/actions/getaction/' + surveyName + '/' + actionId, { headers: { Authorization: authToken } })
  },

  getActionValue (authToken, surveyName, drillPath, expression, period) {
    return backend({ method: 'post', url: 'vuejs/actions/getactionvalue/' + surveyName + '/' + drillPath + '/' + period, data: expression,  headers: { Authorization: authToken } })
  },

  updateActionData(authToken, surveyName, drillPath, updateDTO) {
    return backend({ method: 'post', url: 'vuejs/actions/updateaction/' + surveyName + '/' + drillPath, data: updateDTO,  headers: { Authorization: authToken } })
  },

  deleteActionData(authToken, surveyName, actionId) {
    return backend.get('vuejs/actions/deleteaction/' + surveyName + '/' + actionId, { headers: { Authorization: authToken } })
  },

  evaluateActions (authToken, surveyName) {
    return backend.get('vuejs/actions/evaluate/' + surveyName, { headers: { Authorization: authToken } })
  },

  fetchPriorityChartData (authToken, surveyName, chartId, drillPath) {
    return backend.get('vuejs/charts/chart/' + surveyName + '/' + chartId + '/' + 'dummy' + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchChoiceLineData(authToken, surveyName, chartId, drillPath) {
    return backend.get('vuejs/charts/chart/' + surveyName + '/' + chartId + '/' + 'dummy' + '/' + drillPath, { headers: { Authorization: authToken } })
  },



  fetchSegmentedDrillPath(authToken, surveyName, drillPath) {
    // console.log('fetchSegmentedDrillPath: ' + authToken + ' dp:' + drillPath)
    return backend.get('vuejs/ranking/segmentedpath/' + surveyName + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchSummaryResultsData(authToken, surveyName, configId, drillPath) {
    // console.log('fetchResultsData: ' + authToken + ' dp:' + drillPath)
    return backend.get('vuejs/results/summary/' + surveyName + '/' + configId + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchDataTableData(authToken, surveyName, configId, drillPath) {
    return backend.get('vuejs/results/datatable/' + surveyName + '/' + configId + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchDrillSuggestions(authToken, surveyName, drillPath, searchTerm) {
    return backend.get('vuejs/results/drillsuggestions/' + surveyName + '/' + drillPath + '/' + searchTerm, { headers: { Authorization: authToken } })
  },

  fetchTextAnalyticsData(authToken, surveyName, drillPath) {
    return backend.get('vuejs/textanalytics/result/' + surveyName + '/' + drillPath, { headers: { Authorization: authToken } })
  },

    searchTextAnalyticsAnswers(authToken, surveyName, drillPath, searchValue) {
//      return backend({ method: 'post', url: 'vuejs/textanalytics/search/' + surveyName + '/' + drillPath, data: { searchValue: searchValue },  headers: { Authorization: authToken } })
      return backend({ method: 'post', url: 'vuejs/textanalytics/search/' + surveyName + '/' + drillPath, data: searchValue,  headers: { Authorization: authToken } })
    },

  searchTextQuestions(authToken, surveyName, configId, drillPath, searchValue) {
    return backend({ method: 'post', url: 'vuejs/textanalytics/searchquestions/' + surveyName + '/' + configId + '/' + drillPath, data: searchValue,  headers: { Authorization: authToken } })
  },

  searchTextQuestionDownloadUrl(authToken, surveyName, configId, drillPath, searchValue) {
    const rootURL = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : ''
    let url = rootURL + '/rest/vuejs/textanalytics/downloadcomments/' + surveyName + '/' + configId + '/' + drillPath + "?auth=" + authToken + "&search=" + searchValue;
    // console.log(url)
    return url
  },



  /*
    validateSampleServerside(authToken, surveyName, sampleId, newState, sampleData) {
      return backend({ method: 'post', url: 'vuejs/sample/validate/' + surveyName + '/' + sampleId + '/' + newState, data: sampleData,  headers: { Authorization: authToken } })
    },

   */

  fetchBigScreenData(apiKeytoken) {
    return backend.get('vuejs/bigscreen/data/' + apiKeytoken )
  },



}