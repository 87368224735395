<template>
  <v-data-table v-if="leafRows===false"
                :headers="liveHeaders"
                :items="tabledata"
                class="elevation-2"
                sort-by="rank"
                hide-default-footer

                :custom-sort="customSort"

                :items-per-page="1000"
                fixed-header
                ref = "tableRef"
                :loading="loading"
                must-sort>
    <!-- ***************** HEADERS ************** -->

    <template v-slot:top>
      <v-text-field v-model="nodeFilter" :label="$t('TABLE_FILTER')" :hint="$t('RANK_TABLE_FILTER_HINT')" class="mx-4" @change="onFilterEnter2" @click:clear="nodeFilter=null; onFilterEnter2()" clearable></v-text-field>
    </template>

    <template v-slot:[dynamicSlotName]="{ header }">
      {{ header.text }}

      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn icon color="primary" dark v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list class="extracolmenu">
          <v-list-item v-for="(header,idx) in extraHeaders" :key="idx">
            <v-list-item-title class="mx-0" @click="onExtraHeaderSelected(header)">{{header.text}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </template>

    <!-- ***************** CELLS ************** -->
    <template v-slot:item="{ item }">
      <tr @click="onClickRow(item)">
        <template v-for="(header, index) in liveHeaders">
          <td :class="cellClass(header, index)"  :key="index+header.value">
            <LITSCell v-if="header.renderType==='lits'" :targets="item.cells[header.cellIndex]"/>
            <AlertStateCell v-else-if="header.renderType==='alertstate'" :alertid="item.alertId" :alertstate="item.cells[header.cellIndex]" />
            <template v-else>
              {{ renderCell(header, item.cells) }}
              <TargetBall :target="item.rankTarget" v-if="header.value===rankingVar" />
            </template>
          </td>
        </template>
      </tr>
    </template>


    <!-- ***************** FOOTER ************** -->
    <template v-slot:body.append="{  }" v-if="tablefooter!==null">
      <tr>
        <template v-for="(header, index) in liveHeaders">
          <td :class="[cellClass(header, index), 'footerCell']"  :key="index+header.value">
            <template>
              {{ renderFooterCell(header, index, tablefooter.cells) }}
            </template>
          </td>
        </template>
      </tr>
    </template>

  </v-data-table>

<!--  must-sort-->

  <v-data-table v-else
                :headers="liveHeaders"
                :items="tabledata"
                class="elevation-2"
                :server-items-length="totalRows"
                :items-per-page="50"
                @update:options="onOptionsChanged"
                :loading-text="$t('RANKINGTABLE_LOADING')"
                :loading="loading"
                fixed-header
                ref = "tableRef"
                :options="tableOptions"
                :custom-sort="customSort"
                :footer-props="{ itemsPerPageOptions: [25,50,100]}"
  >

    <template v-slot:top>
      <v-text-field v-model="nodeFilter" :hint="$t('RANK_TABLE_FILTER_HINT')" :label="$t('TABLE_FILTER')" class="mx-4" @change="onFilterEnter2" @click:clear="nodeFilter=null; onFilterEnter2()" clearable></v-text-field>
    </template>

    ***************** HEADERS **************

    <!-- ***************** CELLS ************** -->
    <template v-slot:item="{ item  }">
      <tr @click="onClickRow(item)">
        <template v-for="(header, index) in liveHeaders">
          <td :class="cellClass(header, index)"  :key="index+header.value">
            <LITSCell v-if="header.renderType==='lits'" :targets="item.cells[header.cellIndex]"/>
            <AlertStateCell v-else-if="header.renderType==='alertstate'" :alertid="item.alertId" :alertstate="item.cells[header.cellIndex]" :leaf-level="true" :alert-list="alertList"/>
            <SampleStateCell v-else-if="header.renderType==='state'" :state="item.cells[header.cellIndex].value" :title="item.cells[header.cellIndex].svalue"/>
            <template v-else>
              {{ renderCell(header, item.cells) }}
              <TargetBall :target="item.rankTarget" v-if="header.target" />
            </template>
          </td>
        </template>
      </tr>
    </template>

    <!-- ***************** FOOTER ************** -->
    <template v-slot:body.append="{ }" v-if="tablefooter!==null">
      <tr>
        <template v-for="(header, index) in liveHeaders">
          <td :class="[cellClass(header, index), 'footerCell']"  :key="index+header.value">
            <template>
              {{ renderFooterCell(header, index, tablefooter.cells) }}
            </template>
          </td>
        </template>
      </tr>
    </template>

  </v-data-table>

</template>

<script>
import _ from 'lodash'
import LITSCell from "../../components/LITSCell";
import TargetBall from "../../components/TargetBall";
import AlertStateCell from "../../components/AlertStateCell";
import SampleStateCell from "../../components/SampleStateCell";
import {mapState} from "vuex";

export default {
  components: {LITSCell, TargetBall, AlertStateCell, SampleStateCell},
  data: () => ({
    tableTop: 0, // y-position of top of table
    innerHeight: 0,

    headerIsWide: [],

    activeExtraColumn: null,
    activeExtraColumnIndex: 0,

    liveHeaders: [],
    numberFixedCells: 0,

    nodeFilter: null,
    search: null,
    select: null,
    mysearch: null,
    lastOptions: null
  }),

  props: {
    tableheaders: Object,
    tabledata: Array,
    tablefooter: Object,
    leafRows: Boolean,
    rankingVar: String,
    totalRows: Number,
    loading: Boolean,
    alertList: Boolean,
    tableOptions: Object
  },

  created() {},

  updated: function () {
    // this.$nextTick(function () {
    //     this.onResize()
    //     // Code that will run only after the
    //     // entire view has been re-rendered
    // })
  },

  mounted() {
    // window.addEventListener("resize", this.onResize);
    // this.$nextTick(this.onResize);
  },

  watch: {
    tableheaders: {
      handler() {
        this.calculateLiveHeaders();
      },
      immediate: true
    },

    select(searchTerm) {
      // console.log("Select: " + searchTerm)
      this.$store.commit('setFilterTerm', searchTerm)
    },

    search(searchTerm) {
      // console.log("Search: " + searchTerm)
      if (searchTerm && searchTerm !== this.select) {
        this.$store.commit('setSearchTerm', searchTerm)
      }
    }

  },

  computed: {
    ...mapState(['searchSuggestions']),

    extraHeaders: function() {
      return _.filter(this.tableheaders.headers, {extra: true})
    },

    nameWidth: function () {
      return '30%'
    },

    valueWidth: function () {
      let pct = 100 - (30)
      pct = (pct / this.tableheaders.headers.length)
      return pct + '%'
    },

    dynamicSlotName: function () {
      return (this.activeExtraColumn!==null) ? "header."+this.activeExtraColumn.value : null
    },
  },



  methods: {

    // onFilterEnter() {
    //     this.$store.commit('setFilterTerm', this.nodeFilter)
    // },
    //
    onFilterEnter2() {
      if(this.lastOptions) {
        this.onOptionsChanged(this.lastOptions)
      } else {
        this.$emit('request-data', {rowFrom: 1, rowTo: 1000, sortVar: "none", sortDesc: false, filter: this.nodeFilter})
      }
    },

    onOptionsChanged(options) {
       // console.log("onOptionsChanged")
       // console.log(options.sortBy[0])

      this.lastOptions = options;

      const pageNumber = options.page
      const pageSize = options.itemsPerPage

      const from = (pageNumber-1)*pageSize
      const to = from + pageSize

      this.$emit('request-data', {rowFrom: from, rowTo: to, sortVar: options.sortBy[0], sortDesc: options.sortDesc[0], filter: this.nodeFilter})
    },

    onExtraHeaderSelected(header) {
      this.activeExtraColumn = header;
      this.calculateLiveHeaders()
    },

    requestData() {
      const from = (this.pageNumber-1)*50
      const to = (this.pageNumber)*50
      this.$emit('request-data', {rowFrom: from, rowTo: to, sortVar: this.sortVar, sortDesc: this.sortDesc})
    },

    headerClass(header, index) {
      if(header.value.startsWith("namevar")) {
        if(index === (this.tableheaders.nameCount-1)) {
          return 'nameHeaderCell lastname'
        }
        return 'nameHeaderCell'
      }
      return 'valueHeaderCell'
    },

    cellClass(header) {
      let classes = "valueCell "

      if(header.align==='left') {
        classes += "text-left "
      } else if(header.align==='center') {
        classes += "text-center "
      }


//                if(header.value.startsWith("namevar")) {
      if(header.cellIndex === (this.tableheaders.nameCount-1)) {
        classes +=" lastname"
      }
//                }

      return classes
    },

    headerWidth(header, index) {
      if(header.value.startsWith("namevar")) {
        if(index === (this.tableheaders.nameCount-1)) {
          return this.nameWidth
        }
      }
      return this.valueWidth
    },

    calculateLiveHeaders() {
      this.liveHeaders = _.filter(this.tableheaders.headers, {extra: false})
      this.numberFixedCells = this.liveHeaders.length


      if(this.activeExtraColumn == null) {
        const firstExtra = _.find(this.tableheaders.headers, {extra: true})

        if(firstExtra) {
          this.activeExtraColumn = firstExtra
          this.activeExtraColumnIndex = _.findIndex(this.tableheaders.headers, {value: firstExtra.value, extra: true})
          this.liveHeaders.push(this.activeExtraColumn)
          // console.log("Active extra column: " + this.activeExtraColumn.value + " index: " + this.activeExtraColumnIndex)
        }

      } else {
        this.liveHeaders.push(this.activeExtraColumn)
      }

      // console.log("liveHeaders initialized: " + this.liveHeaders.length)

    },

    getValueHeader(index) {
      const h = this.tableheaders.headers[index+this.tableheaders.nameCount]

      if(!h) {
        // console.log("Asked for valueheader: " + index)
      }

      return h
    },


    setActiveExtraColumn(header) {
      this.activeExtraColumn = header
      this.activeExtraColumnIndex = _.findIndex(this.tableheaders.headers, {value: header.value, extra: true})
      // console.log("New active extra column: " + this.activeExtraColumn.value + " index: " + this.activeExtraColumnIndex)

      this.calculateLiveHeaders()

      // this.liveHeaders = _.filter(this.tableheaders.headers, {extra: false})
      // this.liveHeaders.push( header )

    },

    getLiveCells(cells) {
      var result = cells.slice(0, this.numberFixedCells-1)

      if(this.activeExtraColumn != null) {
        result.push( cells[this.activeExtraColumnIndex  - this.tableheaders.nameCount ])
      }

      return result;
    },

    // onMouseOver(index) {
    //     this.$set(this.headerIsWide, index, true);
    // },
    //
    // onMouseLeave(index) {
    //     this.$set(this.headerIsWide, index, false);
    // },

    onClickRow(row){
      if(row.accessAllowed) {
        if(row.interviewId && row.isComplete) {
          this.$emit('view-interview', row.interviewId)
        } else if(row.interviewId && !row.isComplete) {
          this.$emit('view-sample', row.interviewId)
        } else if(!row.interviewId && row.alertId && this.leafRows) {
          this.$emit('view-alert', row.alertId)
        } else {
          this.$emit('drill-to', row.drillPath)
        }
      }

    },

    renderCell(header, cellarray) {
      let celltext = ''

      const celldata = cellarray[header.cellIndex]

      if(!celldata) {
        // console.log("--------------------------------------------- NO CELL for " + header.value);
        return ''
      }

      if(header.renderType==='string') {
        celltext = celldata ? celldata : '-'
      } else if(header.renderType==='number') {
        celltext = celldata.svalue ? celldata.svalue : ''
      }  else if(header.renderType==='lits') {
        celltext = "lits:" + celldata
      }else if(header.renderType==='alertstate') {
        celltext = '<a>' + celldata + '</a>'
      } else if(header.renderType==='custom') {
        celltext = "custom";
      } else {
        celltext = header.renderType
      }

      return celltext
    },


    renderFooterCell(header, index, cellarray) {
      if(header.renderType==='string') return ""
      if(header.renderType==='alertstate') return ""
      if(header.renderType==='lits') return ""
      if(header.renderType==='state') return ""
      if(header.value==='rank') return "Total"

      return this.renderCell(header, cellarray)
    },

    customSort(items, varnames, sortDirections) {
      // console.log("customSort var: " + varnames[0] + " desc:" + sortDirections[0])

      if(!varnames || varnames.length==0) {
        // console.log("Ignoring sort")
        return items
      }

      const sortVar = varnames[0]
      const sortDesc= sortDirections[0]

      let header = _.find(this.liveHeaders, { 'value': sortVar })

      if(header==null) {
        // console.log("Failed finding header for sortVar: " + sortVar)
        return items
      }

      items.sort((a, b) => {
        const cellA = a.cells[header.cellIndex]
        const cellB = b.cells[header.cellIndex]


        if(typeof cellA === 'undefined') {
          return sortDesc ? -1 : 1
        }
        if(typeof cellB === 'undefined') {
          return sortDesc ? -1 : 1
        }

        var sortResult = true

        if (header.renderType === 'number') {
          sortResult = cellA.value < cellB.value
        } else if(header.renderType === 'string') {
          sortResult = cellA < cellB
        }

        if(sortDesc===true) {
          return sortResult ? 1 : -1;
        } else {
          return sortResult ? -1 : 1;
        }


      });

      return items;
    }

  }

}
</script>

<style>
/*.v-data-table__wrapper{height: 45vh !important;}*/
/*.v-data-table__wrapper{height: 45vh !important;}*/
</style>

<style scoped>
th.rankHeaderCell {
  padding: 0 10px !important;
  text-align: center !important;
  overflow: initial !important;
  white-space: initial  !important;;
}

th.nameHeaderCell {
  padding: 0 10px !important;
  text-align: left !important;
  overflow: initial !important;
  white-space: initial  !important;;
}

th.valueHeaderCell {
  padding: 0 10px !important;
  overflow: initial !important;
  white-space: initial  !important;;
}

td.valueCell {
  padding: 0 10px !important;
  text-align: left;
}

td.footerCell {
  font-weight: bold;
}

.lastname {
  border-right:1px solid #dddddd;
}

td.rankCell {
  font-weight: bold;
}

.extracolmenu {
  min-width: 10em;
}

</style>
