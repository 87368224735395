<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <span class="maintitle">{{blockData.title}}: {{selectedGroup.title}}</span>

        <v-menu class="float-right">
          <template v-slot:activator="{ on }">
            <v-btn icon color="primary" dark v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(group,idx) in blockData.groups" :key="idx">
              <v-list-item-title @click="onGroupSelected(idx)" class="mx-0">{{group.title}}</v-list-item-title>
            </v-list-item>
          </v-list>

        </v-menu>

      </v-col>

    </v-row>

    <v-row v-if="blockData">
      <template v-for="(vardata, index) in selectedGroup.variables">
        <v-col cols="2" :key="index">
          <v-skeleton-loader v-if="loading" type="image"></v-skeleton-loader>
          <IndicatorVariableComponent v-else @select="(universeMetaInterview) => $emit('select', universeMetaInterview)" :vardto="vardata"/>
        </v-col>
      </template>
    </v-row>

    <v-row v-else>
      <v-col v-for="i in 6" :key="i">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import {mapGetters} from "vuex";
import IndicatorVariableComponent from "@/views/login/IndicatorVariableComponent";

export default {
  name: 'IndicatorBlockComponent',
  components: {
    IndicatorVariableComponent
  },

  data () {
    return {
      selectedGroupIndex: null
    }
  },

  computed: {
    selectedGroup() {
      return this.blockData.groups[this.selectedGroupIndex]
    },

    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'isUserRoot']),
  },

  created () {
    this.selectedGroupIndex = 0
  },

  props: {
    blockData: Object,
    loading: Boolean
  },


  methods: {
    onGroupSelected(idx) {
      this.selectedGroupIndex = idx
    }
  }

}
</script>
<style scoped>
span.maintitle {
  /*font-size: 22px;*/
  font-size: calc((22/16)*1rem);
  font-weight: bold;
}
</style>
