<template>
  <div class="wrapper fill-height-or-more">

    <div class="row_one">
      <div class="headerDiv">
        <img class="topLogo" :src="bigScreenData.logoURL" alt="logo"/>
        <div class="topTitle">{{bigScreenData.pathName}}</div>
      </div>
    </div>
    <div class="row_two">

      <div class="ratingColumn">

        <v-container fluid class="mainColumn" >
          <v-row align="center" justify="center">
            <v-col>
              <div class="questionTitle">{{bigScreenData.ratingTitle}}</div>
            </v-col>
          </v-row>

          <v-divider class="vdivider"/>

          <v-row align="center" justify="center">
            <v-col>
              <div class="mainQuestionScore">{{bigScreenData.avgRatingText}}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-rating v-model="bigScreenData.avgRating" background-color="indigo lighten-3" color="yellow" size="5vw" half-increments readonly></v-rating>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex" style="align-items: center; justify-content: center">
<!--              <div><v-icon color="#646a74" size="2vw">mdi-bullseye</v-icon></div>-->
              <img src="../../assets/animating_target.gif" width="30vw" height="30vw" alt="target">
              <div class="evaluations"> {{bigScreenData.evaluationsText}}</div>
            </v-col>
          </v-row>


          <v-divider class="vdivider"/>

          <v-row  >
            <v-col align="center" justify="center">
              <div class="providedBy">{{ bigScreenData.deliveredByText }}</div>
              <img src="../../assets/ag_logo.png" alt="target">
            </v-col>
          </v-row>


        </v-container>

      </div>
      <div class="commentColumn">
        <v-container fluid class="mainColumn" style="display: flex; align-items: center">
          <v-carousel cycle hide-delimiter-background show-arrows-on-hover max="20" style="height:60vh">
            <v-carousel-item v-for="(slide, i) in bigScreenData.slides" :key="i">
              <v-sheet color="#242d3c">
                <v-container fluid>
                  <v-row>
                    <v-col offset="2">
                      <v-rating v-model="slide.starRating" color="white" background-color="white" size="4vw" half-increments readonly></v-rating>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="8" offset="2">
                      <div class="questionAnswer">{{slide.comment}}</div>
                    </v-col>
                  </v-row>

                  <v-row >
                    <v-col cols="8" offset="2">
                      <div class="interviewDate">{{slide.evaluation}}</div>
                    </v-col>
                  </v-row>

                </v-container>

              </v-sheet>

            </v-carousel-item>
          </v-carousel>

        </v-container>


      </div>

    </div>
  </div>

</template>

<script>
import {mapGetters, mapState} from 'vuex'
import ResultsService from "@/services/ResultsService";

export default {
  name: 'bigscreen',

  data: function () {
    return {
      mainRating: 4.5,
      questionRating: 5,

      cookieLogin: false,

      valid: false,

      showWaitScreen: false,

      username: '',

      warningText: null,

      bigScreenData: {},
    }
  },

  computed: {
    ...mapState(['userIdentity', 'activeSurvey', 'activeDrillPath']),
    ...mapGetters(['getFilteredDrillPath']),
  },


  methods: {
    loadData: function () {
      const token = this.$route.params.apiKeyToken

      ResultsService.fetchBigScreenData(token).then((response) => {
        this.bigScreenData = response.data
        this.slides = response.data.slides
      })

    }

  },


  watch: {
    $route: {
      handler() {
        this.loadData();
      },
      immediate: true
    },

  }



}
</script>

<style scoped>


.row_one {
  display: flex;
  /*background: blue;*/
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

div.headerDiv {
  display: flex;
  align-items: center;
}


div.row_two {
  display: flex;
  flex: 8;
  flex-direction: row;
}

.ratingColumn {
  flex: 3;
  background-color: #802420;
  margin: 10px;
}

.commentColumn {
  flex: 9;
  background-color: #8fbde7;
  margin: 10px;
}


.fill-height-or-more {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/*.fill-height-or-more > div {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*}*/











div.wrapper {
  background-color: #1d2633;
}

.mainColumn {
  background-color: #242d3c;
  height: 100%;
}

.topLogo {
  width: 5vw;
  height: 5vw;
}

.topTitle {
  color: #ffffff;
  font-size: 5vw;
  margin-left: 10px;
}

.questionTitle {
  color: #ffffff;
  font-size: 2.5vw;
  text-align: center;
}

.mainQuestionScore {
  color: #ffffff;
  font-size: 10vw;
  text-align: center;
}

.questionAnswer {
  color: #ffffff;
  font-size: 2vw;
}

.interviewDate {
  color: #646a74;
  font-size: 2vw;
}

.evaluations {
  color: #646a74;
  text-align: center;
  font-size: 1.5vw;
}

.vdivider {
  border-width: medium;
  border-color: #1d2633;
}

.providedBy {
  color: #646a74;
  font-size: 1vw;
}


</style>

