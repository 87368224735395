import backend from '@/services/backend'

export default {

  fetchCopyActionData (authToken, surveyName, configId, copyAction, drillPath) {
    return backend.get('vuejs/sampleactions/copyaction/' + surveyName + '/' + configId + '/' + copyAction + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchMailActionData(authToken, surveyName, configId, actionName, drillPath) {
    return backend.get('vuejs/sampleactions/mailaction/' + surveyName + '/' + configId + '/' + actionName + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  executeCopyAction(authToken, surveyName, configId, copyAction, drillPath, copyActionData) {
    return backend({ method: 'post', url: 'vuejs/sampleactions/executecopyaction/' + surveyName + '/' + configId + '/' + copyAction + '/' + drillPath, data: copyActionData,  headers: { Authorization: authToken } })
  },

  executeMailAction(authToken, surveyName, configId, copyAction, drillPath, mailActionData) {
    return backend({ method: 'post', url: 'vuejs/sampleactions/executemailaction/' + surveyName + '/' + configId + '/' + copyAction + '/' + drillPath, data: mailActionData,  headers: { Authorization: authToken } })
  },

  fetchTemplateExample(authToken, surveyName, configId, copyAction, drillPath, mailActionData) {
    return backend({ method: 'post', url: 'vuejs/sampleactions/templateexample/' + surveyName + '/' + configId + '/' + copyAction + '/' + drillPath, data: mailActionData,  headers: { Authorization: authToken } })
  },

  createSample(authToken, surveyName, drillPath) {
    return backend.get('vuejs/sampleactions/create/' + surveyName + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchSamplesListData (authToken, surveyName, configId, drillPath, rowFrom, rowTo, sortVar, sortDesc, filterTerm) {
    const safeFilterTerm = (filterTerm && filterTerm.length>0) ? filterTerm : "none";
    return backend.get('vuejs/sample/list/' + surveyName + '/' + configId + '/' + drillPath + '/' + rowFrom + '/' + rowTo + '/' + sortVar + '/' + sortDesc + '/' + safeFilterTerm, { headers: { Authorization: authToken } })
  },

  deleteSamplesListData (authToken, surveyName, configId, drillPath, rowFrom, rowTo, sortVar, sortDesc, filterTerm) {
    const safeFilterTerm = (filterTerm && filterTerm.length>0) ? filterTerm : "none";
    return backend.get('vuejs/sample/delete/' + surveyName + '/' + configId + '/' + drillPath + '/' + rowFrom + '/' + rowTo + '/' + sortVar + '/' + sortDesc + '/' + safeFilterTerm, { headers: { Authorization: authToken } })
  },


  fetchSampleData (authToken, surveyName, sampleId) {
    return backend.get('vuejs/sample/details/' + surveyName + '/' + sampleId, { headers: { Authorization: authToken } })
  },

  updateSampleData(authToken, surveyName, sampleId, resendEmail, resendSMS, sampleData) {
    return backend({ method: 'post', url: 'vuejs/sample/details/' + surveyName + '/' + sampleId + '/' + resendEmail + '/' + resendSMS, data: sampleData,  headers: { Authorization: authToken } })
  },

  validateSampleServerside(authToken, surveyName, sampleId, newState, sampleData) {
    return backend({ method: 'post', url: 'vuejs/sample/validate/' + surveyName + '/' + sampleId + '/' + newState, data: sampleData,  headers: { Authorization: authToken } })
  },

  fetchSampleListSuggestions(authToken, surveyName, drillPath, searchTerm) {
//    console.log('fetchSampleListSuggestions: ' + authToken + ' dp:' + drillPath + " searchterm: " + searchTerm)
    return backend.get('vuejs/sample/suggestions/' + surveyName + '/' + drillPath + '/' + searchTerm, { headers: { Authorization: authToken } })
  },

  deleteSample(authToken, surveyName, sampleId) {
    return backend.get('vuejs/sample/delete/' + surveyName + '/' + sampleId, { headers: { Authorization: authToken } })
  },



   // sample editor
  fetchSampleEditorData(authToken, surveyName, configId, sampleId) {
    return backend.get('vuejs/sampleeditor/' + surveyName + '/' + configId + '/' + sampleId, { headers: { Authorization: authToken } })
  },

  updateSampleEditorData(authToken, surveyName, configId, sampleId, editorData, runHandleSampleUpdated) {
    return backend({ method: 'post', url: 'vuejs/sampleeditor/' + surveyName + '/' + configId + '/' + sampleId + '/' + runHandleSampleUpdated, data: editorData,  headers: { Authorization: authToken } })
  },

  handleMailActionButton(authToken, surveyName, sampleId, template, mailVariable) {
    return backend.get('vuejs/sampleeditor/mailaction/' + surveyName + '/' + sampleId + '/' + template + '/' + mailVariable, { headers: { Authorization: authToken } })
  },

  fetchComments(authToken, surveyName, sampleId) {
    return backend.get('vuejs/sampleeditor/comments/' + surveyName + '/' + sampleId, { headers: { Authorization: authToken } })
  },

  createComments(authToken, surveyName, sampleId, comments) {
    return backend({ method: 'post', url: 'vuejs/sampleeditor/comments/' + surveyName + '/' + sampleId, data: comments,  headers: { Authorization: authToken } })
  },

  resendInvitation(authToken, surveyName, sampleId, email) {
    return backend.get('vuejs/sampleeditor/resend/' + surveyName + '/' + sampleId +'/' + email, { headers: { Authorization: authToken } })
  },

}
