<template>
  <v-container fluid v-if="!componentData.config.rootOnly || (componentData.config.rootOnly && isUserRoot)">
    <v-row v-if="!variables">
      <v-col v-for="i in numberComponents" :key="i">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else>
      <template v-for="(vardata, index) in variables">
        <v-col :cols="12/variables.length"
               :key="index"
               :class=" getBlockCssClasses(index) "
               @click="selectVariableComponent(index)"
        >
            <MainVariableComponent v-if="index===0"
                                   :vardto="vardata"
            />
            <VariableComponent v-else
                               :vardto="vardata"
            />
        </v-col>
      </template>
      <span v-if="componentData.config.rootOnly" style="font-size: 10px; margin-left: 20px">root only</span>
    </v-row>
    <v-row v-if="selectedIndex != null">
      <v-col cols="12">
        <VariableBlockChartComponent
            :componentData="componentData"
            :variables="variables"
            :index="selectedIndex"
            @close="selectedIndex = null"
        />
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {mapGetters} from "vuex";
import VariableComponent from "./VariableComponent";
import VariableBlockChartComponent from "./VariableBlockChartComponent";
import ResultsService from "../../../services/ResultsService";
import MainVariableComponent from "./MainVariableComponent";

export default {
  name: 'VariableBlockComponent',

  components: {
    MainVariableComponent, VariableBlockChartComponent, VariableComponent
  },

  data () {
    return {
      variables: null,
      selectedIndex: null
    }
  },

  props: {
    componentData: Object,
    vardto: Object
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'isUserRoot']),

    anyExpressions () {
      return this.componentData.config.expressions.length > 0 || this.componentData.config.blocks.length > 0
    },

    numberComponents() {
      return 6
      // if(this.componentData.config.expressions && this.componentData.config.expressions.length>0) {
      //   return this.componentData.config.expressions.length
      // }
      //
      // return this.componentData.config.variables.length
    },



  },

  methods: {
    getBlockCssClasses (index) {
      if (this.anyExpressions) {
        return 'variable-block' + (this.selectedIndex === index ? ' selected-variable' : '')
      } else {
        return ''
      }
    },
    loadChartData() {
      ResultsService.fetchVariableBlockData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
        this.variables = response.data.variables
      })
    },
    selectVariableComponent(index) {
      if (this.anyExpressions) { // only support for blocks with expressions.
        if (this.selectedIndex === null) {
          this.selectedIndex = index
        } else {
          if (this.selectedIndex === index) {
            this.selectedIndex = null
          } else {
            this.selectedIndex = index;
          }
        }
      }
    }
  }

}
</script>
<style scoped>
  .selected-variable > .container {
    /*border: 1px solid #008EFF;*/
    border: 1px solid #0D47A1;
  }
  .variable-block > .container {
    border: 1px solid transparent;
  }
  .variable-block > .container:hover {
    cursor: pointer;
    border: 1px solid #008EFF;
  }
</style>
