<template>
  <div class="drillpathbar">
    <div class="pathpart">
      <v-breadcrumbs v-if="segmentedDrillPath" :items="pathSegments">
        <template v-slot:divider>
          <v-icon>mdi-slash-forward</v-icon>
        </template>
        <template v-slot:item="props">
          <DrillPathSelector :path-segment="props.item" @path-selected="$emit('path-selected', $event)"/>
        </template>
      </v-breadcrumbs>
    </div>
    <div v-if="showFilter" class="buttonspart">
      <v-autocomplete
          class="filterComboBox"
          :label="$t('SEARCH')"
          :no-data-text="$t('NO_DATA_AVAILABLE')"
          v-model="select"
          :search-input.sync="search"
          :items="drillSuggestions"
          item-text="name"
          item-value="value"
          auto-select-first
          no-filter
          clearable/>

      <v-btn d-inline text icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </div>

    <div v-if="showDownload" class="buttonspart">
      <ReportDownloadMenuComponent :interview-id="interviewId" :sample-id="sampleId" :ranking-list-report="rankingListReport"/>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import DrillPathSelector from "./DrillPathSelector";
import ReportDownloadMenuComponent from "./ReportDownloadMenuComponent";
import ResultsService from "../services/ResultsService";

export default {
  name: 'DrillPathComponent',
  components: {ReportDownloadMenuComponent, DrillPathSelector},

  props: {
    leaf: Boolean,
    showFilter: Boolean,
    showDownload: Boolean,
    interviewId: Number,
    sampleId: Number,
    rankingListReport: Boolean
  },

  data() {
    return {
      items: {},

      search: null,
      select: null,

      drillSuggestions: [],

      components: [
        'Autocompletes', 'Comboboxes', 'Forms', 'Inputs', 'Overflow Buttons', 'Selects', 'Selection Controls', 'Sliders', 'Textareas', 'Text Fields',
      ],
    }
  },

  computed: {
    ...mapState(['activeSurvey', 'segmentedDrillPath', 'searchSuggestions']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath']),

    pathSegments() {
      let segs = []

      for(const seg of this.segmentedDrillPath.segments) {
        if(this.leaf && seg.children.length>0 && seg.children[0].entityId) {
          segs.push( { path: seg.path, children: [] })
        } else {
          segs.push(seg)
        }
      }

      // segs.push({path: "Vælg forhandler", children: [] })

      return segs
    }

  },

  methods: {
    onBackClicked() {
      this.$router.go(-1)
    },


  },

  watch: {
    select(selectTerm) {
      // console.log("Select: " + selectTerm)
      // console.log(selectTerm)
      this.$emit('path-selected', selectTerm)
//                this.$store.commit('setFilterTerm', searchTerm)
//drill
    },

    search(searchTerm) {
      // console.log("Search: " + searchTerm)
      if (searchTerm && searchTerm !== this.select) {

        ResultsService.fetchDrillSuggestions(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath, searchTerm).then((response) => {
          let items = []

          for(let viewResult of response.data.viewResults) {
            items.push( { header: viewResult.header }  )

            for(let nodeResult of viewResult.paths) {
              items.push( { name: nodeResult.text, value: nodeResult.value } )
            }

          }

          this.drillSuggestions = items
        })


        //                    this.$store.commit('setSearchTerm', searchTerm)
      }
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.drillpathbar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-bottom: 1px solid #cccccc;
  margin-top: 14px;
}

.filterComboBox {
  min-width: 24em;
}

li.v-breadcrumbs__divider {
  padding-left: 0;
  padding-right: 0;
}

.v-breadcrumbs {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}


div.pathpart {
  margin-top: 16px;
  margin-bottom: 16px;
  justify-content: flex-start;
  flex: 1;
  font-size: 140%;
  font-weight: bold;
}

div.buttonspart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


</style>
