<template>
  <v-container fluid pa-0>

    <v-row class="pa-3">
      <v-col>
        <v-btn @click="onAddClicked">{{'ADD_USER' | i18n}}</v-btn>
      </v-col>
    </v-row>

    <v-row class="pa-3">
      <v-col>

        <v-data-table
            :search = "search"
            :headers="headers"
            :items="rows"
            item-key="id"
            class="elevation-2"
            :items-per-page="100"
            must-sort
            :sort-by.sync="sortBy"
            @click:row="onRowClicked">

          <template v-slot:top>
            <v-text-field v-model="search" label="Filter" class="mx-4"></v-text-field>
          </template>

          <template v-slot:item.userVariables="{ item }">
            <v-chip class="ml-1 mr-1" v-for="(uv,idx) in  item.userVariables" :key="idx">{{ uv }}</v-chip>
          </template>

        </v-data-table>

      </v-col>
    </v-row>


  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import UsersService from "../../services/UsersService";

export default {
  name: 'UserList',

  components: {
  },

  data () {
    return {
      search: '',
      headers: [
        { text: this.$t('NAME'), align: 'left', value: 'name' },
        { text: this.$t('EMAIL'), value: 'email' },
        { text: 'Last login', value: 'lastLogin' },
        { text: this.$t('ROLE'), value: 'selectedRoleName' },
        { text: this.$t('RIGHTS'), value: 'userVariables' },
        { text: this.$t('STATE_FILTER'), value: 'status' },
        { text: this.$t('NOTIFICATIONS'), value: 'notify' }
      ],

      rows: [],

      sortBy: 'email'


    }
  },


  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath']),

    emailColumn() {
      return this.$t('EMAIL')
    }

  },



  created: function () {
    this.initialFetchData();
  },


  methods: {

    initialFetchData() {
      UsersService.fetchUsersListData(this.getAuthToken, this.activeSurvey.extName).then((response) => {
        this.rows = response.data
      })
    },

    onRowClicked(row) {
      this.navigateUserDetails( row.id )
    },

    onAddClicked() {
      this.navigateUserDetails( 0 )
    },

    navigateUserDetails(userId) {
      this.$router.push({
        name: 'userdetails',
        params: {surveyExtName: this.activeSurvey.extName, userId: userId}
      })
    }

  },


}
</script>
