var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.leafRows===false)?_c('v-data-table',{ref:"tableRef",staticClass:"elevation-2",attrs:{"headers":_vm.liveHeaders,"items":_vm.tabledata,"sort-by":"rank","hide-default-footer":"","custom-sort":_vm.customSort,"items-per-page":1000,"fixed-header":"","loading":_vm.loading,"must-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.$t('TABLE_FILTER'),"hint":_vm.$t('RANK_TABLE_FILTER_HINT'),"clearable":""},on:{"change":_vm.onFilterEnter2,"click:clear":function($event){_vm.nodeFilter=null; _vm.onFilterEnter2()}},model:{value:(_vm.nodeFilter),callback:function ($$v) {_vm.nodeFilter=$$v},expression:"nodeFilter"}})]},proxy:true},{key:_vm.dynamicSlotName,fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","dark":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"extracolmenu"},_vm._l((_vm.extraHeaders),function(header,idx){return _c('v-list-item',{key:idx},[_c('v-list-item-title',{staticClass:"mx-0",on:{"click":function($event){return _vm.onExtraHeaderSelected(header)}}},[_vm._v(_vm._s(header.text))])],1)}),1)],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.onClickRow(item)}}},[_vm._l((_vm.liveHeaders),function(header,index){return [_c('td',{key:index+header.value,class:_vm.cellClass(header, index)},[(header.renderType==='lits')?_c('LITSCell',{attrs:{"targets":item.cells[header.cellIndex]}}):(header.renderType==='alertstate')?_c('AlertStateCell',{attrs:{"alertid":item.alertId,"alertstate":item.cells[header.cellIndex]}}):[_vm._v(" "+_vm._s(_vm.renderCell(header, item.cells))+" "),(header.value===_vm.rankingVar)?_c('TargetBall',{attrs:{"target":item.rankTarget}}):_vm._e()]],2)]})],2)]}},(_vm.tablefooter!==null)?{key:"body.append",fn:function(ref){return [_c('tr',[_vm._l((_vm.liveHeaders),function(header,index){return [_c('td',{key:index+header.value,class:[_vm.cellClass(header, index), 'footerCell']},[[_vm._v(" "+_vm._s(_vm.renderFooterCell(header, index, _vm.tablefooter.cells))+" ")]],2)]})],2)]}}:null],null,true)}):_c('v-data-table',{ref:"tableRef",staticClass:"elevation-2",attrs:{"headers":_vm.liveHeaders,"items":_vm.tabledata,"server-items-length":_vm.totalRows,"items-per-page":50,"loading-text":_vm.$t('RANKINGTABLE_LOADING'),"loading":_vm.loading,"fixed-header":"","options":_vm.tableOptions,"custom-sort":_vm.customSort,"footer-props":{ itemsPerPageOptions: [25,50,100]}},on:{"update:options":_vm.onOptionsChanged},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"hint":_vm.$t('RANK_TABLE_FILTER_HINT'),"label":_vm.$t('TABLE_FILTER'),"clearable":""},on:{"change":_vm.onFilterEnter2,"click:clear":function($event){_vm.nodeFilter=null; _vm.onFilterEnter2()}},model:{value:(_vm.nodeFilter),callback:function ($$v) {_vm.nodeFilter=$$v},expression:"nodeFilter"}})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.onClickRow(item)}}},[_vm._l((_vm.liveHeaders),function(header,index){return [_c('td',{key:index+header.value,class:_vm.cellClass(header, index)},[(header.renderType==='lits')?_c('LITSCell',{attrs:{"targets":item.cells[header.cellIndex]}}):(header.renderType==='alertstate')?_c('AlertStateCell',{attrs:{"alertid":item.alertId,"alertstate":item.cells[header.cellIndex],"leaf-level":true,"alert-list":_vm.alertList}}):(header.renderType==='state')?_c('SampleStateCell',{attrs:{"state":item.cells[header.cellIndex].value,"title":item.cells[header.cellIndex].svalue}}):[_vm._v(" "+_vm._s(_vm.renderCell(header, item.cells))+" "),(header.target)?_c('TargetBall',{attrs:{"target":item.rankTarget}}):_vm._e()]],2)]})],2)]}},(_vm.tablefooter!==null)?{key:"body.append",fn:function(ref){return [_c('tr',[_vm._l((_vm.liveHeaders),function(header,index){return [_c('td',{key:index+header.value,class:[_vm.cellClass(header, index), 'footerCell']},[[_vm._v(" "+_vm._s(_vm.renderFooterCell(header, index, _vm.tablefooter.cells))+" ")]],2)]})],2)]}}:null],null,true)},[_vm._v(" ***************** HEADERS ************** ")])}
var staticRenderFns = []

export { render, staticRenderFns }