var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"mainouter"}},[(_vm.isLoggedIn && _vm.enableMainSidebar)?_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","overlay":"","width":100},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('MainSidebar',{attrs:{"mini-variant":_vm.mini}})],1):_vm._e(),(_vm.enableFilterSidebar)?_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","overlay":"","right":""},model:{value:(_vm.drawerRight),callback:function ($$v) {_vm.drawerRight=$$v},expression:"drawerRight"}},[_c('FiltersSidebar')],1):_vm._e(),(_vm.isLoggedIn && _vm.enableMainSidebar)?_c('v-app-bar',{attrs:{"color":"#f5f5f5","app":"","fixed":"","clipped-left":"","clipped-right":""}},[_c('v-toolbar-items',[_c('div',{staticClass:"preButtonSpacer"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onHomeClicked}},on),[_c('v-icon',{attrs:{"size":"28"}},[_vm._v("mdi-home-outline")])],1)]}}],null,false,3795237570)},[_c('span',[_vm._v(_vm._s(_vm._f("i18n")('HOMEPAGE')))])]),_c('div',{staticClass:"postButtonSpacer"})],1),(_vm.activeSurvey!==null)?_c('SurveyMenuComponent'):_vm._e(),(_vm.showTabsComponent)?_c('ViewTabsComponent'):_vm._e(),_c('v-spacer'),_c('v-toolbar-items',[_c('AlertNotifierComponent'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onAccountClicked}},on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("mdi-account-outline")])],1)]}}],null,false,1016080492)},[_c('span',[_vm._v(_vm._s(_vm._f("i18n")('USER')))])]),(_vm.isUserAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onUsersAdminClicked}},on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("mdi-account-multiple-plus")])],1)]}}],null,false,2752514503)},[_c('span',[_vm._v(_vm._s(_vm._f("i18n")('USER_ADMIN')))])]):_vm._e(),(_vm.isUserRoot)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onBackendClicked}},on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("mdi-wrench-outline")])],1)]}}],null,false,2582832877)},[_c('span',[_vm._v(_vm._s(_vm._f("i18n")('USER_ADMIN')))])]):_vm._e(),(_vm.canChangeUniverse)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onChangeUniverse}},on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("mdi-swap-horizontal")])],1)]}}],null,false,4226652747)},[_c('span',[_vm._v(_vm._s(_vm._f("i18n")('CHANGE_UNIVERSE')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onLogoutClicked}},on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("mdi-logout")])],1)]}}],null,false,1891329953)},[_c('span',[_vm._v(_vm._s(_vm._f("i18n")('LOGOUT')))])])],1),_c('v-toolbar-items',{staticClass:"logoItems"},[_c('LogoComponent')],1)],1):_vm._e(),_c('v-main',{staticClass:"contentDiv"},[_c('router-view',{key:_vm.$route.fullPath})],1),_c('SnackWatcher'),_c('Snackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }