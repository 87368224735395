<template>

  <v-row v-if="isSelectingUniverseByIndicators && !isChangingUniverse">
    <GlobalOverviewComponent @select="onUniverseSelect" :username="userIdentity.username" :global-overview-data="userIdentity.globalOverviewData"/>
  </v-row>

  <v-container v-else fluid fill-height>
    <span v-if="cookieLogin"></span>

    <v-row v-else-if="isSelectingUniverseByIndicators && !isChangingUniverse">
      <GlobalOverviewComponent @select="onUniverseSelect" :username="userIdentity.username" :global-overview-data="userIdentity.globalOverviewData"/>
    </v-row>

    <template v-else>

      <v-row v-if="isStagingServer">
        <v-col style="text-align: center"><h1>Staging Server</h1></v-col>
      </v-row>

      <v-row align-center justify-center>
        <v-col offset=4 cols="4" class="logocol">
          <img v-if="isVGSServer" height="52px" width="220px" src="../assets/vgs_logo.png">
          <img v-else-if="isFTZServer" height="130px" width="267px" src="../assets/ftz_logo.png">
          <img v-else-if="isAutoFlowsServer" height="175px" width="557px" src="../assets/new_autoflows_logo.png">
          <img v-else-if="isPostNordServer" height="52px" width="220pxpx" src="../assets/postnord_logo.png">
          <img v-else width="220px" src="../assets/login_logo.png">
        </v-col>

        <v-col offset=4 cols="4">
          <v-card class="elevation-12 pa-10">
            <v-card-title class="mainTitle">{{textWelcome}}</v-card-title>
            <v-card-subtitle class="subTitle">{{textSignIn}}</v-card-subtitle>

            <div v-if="showWaitScreen" class="waitContainer">
              <v-progress-circular
                  :size="100"
                  :width="4"
                  color="#0000c8"
                  indeterminate
              />

            </div>

            <v-card-text v-else-if="isSelectingActAsUniverseUser">
              <v-autocomplete :items="userIdentity.universeAdminUserList" v-model="actAsSelectedUniverse" item-text="universeName" label="Universe" @change="onSelectedActAsUniverse" return-object></v-autocomplete>
              <v-autocomplete label="Act as" :items="actAsUsers" v-model="actAsSelectedUser" @change="onSelectedActAsUser"></v-autocomplete>
              <v-btn :disabled="actAsSelectedUniverse===null || actAsSelectedUser===null" @click="onSelectedActAsUser">Act as</v-btn>
            </v-card-text>

            <v-card-text v-else-if="!isSelectingUniverse">
              <v-form @keyup.native.enter="onLogin" v-model="valid">
                <v-text-field :rules="userNameFieldRules" v-model.lazy.trim="form.username" name="login" :label="textEMail"
                              type="text" autocomplete="username" required/>
                <v-text-field :rules="passwordFieldRules" v-model.lazy.trim="form.password" id="password" name="password"
                              :label="textPassword" type="password" autocomplete="current-password" required/>
              </v-form>

              <div class="loginButtonPanel">
                <v-btn :disabled="!valid" type="submit" color="primary" @click="onLogin">{{textLoginButton}}</v-btn>
                <v-checkbox class="ma-0" v-model="form.remember" :label="textRememberMe"/>
              </div>

              <div class="forgotSignupPanel">
                <a @click="onForgotClicked">{{textForgotPassword}}</a>
                <a v-if="isFTZServer" class="frontLink"  href="https://survey.aganalytics.dk/s3/Workshop365-Autoflows">{{textSignUp}}</a>
                <a v-else-if="isVGSServer" class="frontLink"  href="https://aganalytics.dk/vgs-helpdesk">{{textSignUp}}</a>
                <a v-else class="frontLink"  href="https://aganalytics.dk/contact">{{textSignUp}}</a>
              </div>

              <v-alert transition="scale-transition" :value="userIdentity!==null && userIdentity.loginStatus!==0 && userIdentity.loginStatus!==5 && userIdentity.loginStatus!==6" type="error">{{textLoginFailed}}</v-alert>
              <v-spacer></v-spacer>

            </v-card-text>

            <v-card-text v-else>


              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(uniTitle, uniName) in userIdentity.universes" :key="uniName"
                               @click="onUniverseSelect({universe: uniName})">
                    <v-list-item-icon>
                      <TinyLogoComponent :universe="uniName" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="uniTitle"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

            </v-card-text>


          </v-card>
        </v-col>
      </v-row>



      <v-row no-gutters class="footerRow">
        <img v-if="isVGSServer" style="margin-bottom: 14px" width="220px" src="../assets/login_logo.png">
        <img v-else-if="isFTZServer" style="margin-bottom: 14px" width="220px" src="../assets/ag_autoflows_logo.png">
        <div>ag analytics &middot; +45 7020 1075 &middot; <a class="frontLink" href="mailto:support@aganalytics.dk">{{textSupportMail}}</a></div>
      </v-row>



    </template>

  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import AuthService from "../services/AuthService";
import PageNavigator from "../pagenavigator"
import _ from 'lodash'
import TinyLogoComponent from "../components/TinyLogoComponent";
import GlobalOverviewComponent from "@/views/login/GlobalOverviewComponent";
import UsersService from "@/services/UsersService";

export default {
  name: 'login',

  components: {GlobalOverviewComponent, TinyLogoComponent },

  data: function () {
    return {
      changeUniverseState: false,
      cookieLogin: false,

      valid: false,

      showWaitScreen: false,

      userNameFieldRules: [
        v => !!v || this.textUsernameRequired
      ],

      passwordFieldRules: [
        v => !!v || this.textPasswordRequired
      ],

      loginFailed: false,
      isSubmitted: false,
      form: {
        username: '',
        password: '',
        remember: false
      },

      actAsUsers: [],
      actAsSelectedUniverse: null,
      actAsSelectedUser: null
    }
  },

  computed: {
    ...mapState(['userIdentity', 'activeSurvey', 'activeDrillPath']),
    ...mapGetters(['getFilteredDrillPath', 'forwardToVGSServer', 'loginToFinnishUniverse', 'getAuthToken']),

    textWelcome() {
      if(this.isSwedishServer) {
        return "Välkommen"
      } else if(this.isFTZOrAuto) {
        return "Velkommen"
      } else if(this.isFinlandServer) {
        return "Tervetuloa takaisin"
      }
      return "Welcome back"
    },

    textSignIn() {
      if(this.isSwedishServer) {
        return "Logga in"
      } else if(this.isFTZOrAuto) {
        return "Log på"
      } else if(this.isFinlandServer) {
        return "Kirjaudu sisään"
      }
      return "Sign into your account"
    },

    textEMail() {
      if(this.isSwedishServer) {
        return "e-postadress"
      } else if(this.isFTZOrAuto) {
        return "e-mail"
      } else if(this.isFinlandServer) {
        return "Sähköposti"
      }
      return "e-mail"
    },

    textPassword() {
      if(this.isSwedishServer) {
        return "lösenord"
      } else if(this.isFTZOrAuto) {
        return "adgangskode"
      } else if(this.isFinlandServer) {
        return "Salasana"
      }
      return "password"
    },

    textLoginButton() {
      if(this.isSwedishServer) {
        return "Logga in"
      } else if(this.isFTZOrAuto) {
        return "Log på"
      } else if(this.isFinlandServer) {
        return "Kirjaudu"
      }
      return "login"
    },

    textForgotPassword() {
      if(this.isSwedishServer) {
        return "glömt lösenord?"
      } else if(this.isFTZOrAuto) {
        return "glemt adgangskode?"
      } else if(this.isFinlandServer) {
        return "Unohditko salasanasi?"
      }
      return "forgot your password?"
    },

    textRememberMe() {
      if(this.isSwedishServer) {
        return "kom ihåg mig"
      } else if(this.isFTZOrAuto) {
        return "husk mig"
      } else if(this.isFinlandServer) {
        return "muista minut"
      }
      return "remember me"
    },

    textSignUp() {
      if(this.isSwedishServer) {
        return "CEM HelpDesk ➔"
      } else if(this.isFTZOrAuto) {
        return "tilmeld dig"
      } else if(this.isFinlandServer) {
        return "luo tili"
      }
      return "sign up"
    },

    textUsernameRequired() {
      if(this.isSwedishServer) {
        return "ange e-postadress"
      } else if(this.isFTZOrAuto) {
        return "angiv e-mail"
      } else if(this.isFinlandServer) {
        return "käyttäjätunnus vaaditaan"
      }
      return "username required"
    },

    textPasswordRequired() {
      if(this.isSwedishServer) {
        return "ange lösenord"
      } else if(this.isFTZOrAuto) {
        return "angiv adgangskode"
      } else if(this.isFinlandServer) {
        return "salasana vaaditaan"
      }
      return "password required"
    },

    textLoginFailed() {
      if(this.isSwedishServer) {
        return "inloggningen misslyckades"
      } else if(this.isFTZOrAuto) {
        return "log ind fejlede"
      } else if(this.isFinlandServer) {
        return "kirjautuminen epäonnistui"
      }
      return "login failed"
    },

    textSupportMail() {
      return this.isSwedishServer ? "support@aganalytics.se" :  "support@aganalytics.dk"
    },

    isStagingServer() {
      return !this.isProductionServer && !this.isVGSServer && !this.isFTZServer && !this.isAutoFlowsServer && !this.isPostNordServer && !this.isFinlandServer
    },

    isProductionServer() {
      return window.location.origin.includes("dashboard.aganalytics.dk") || window.location.origin.includes("dashboard.aganalytics.se") /*|| window.location.origin.includes("dashboard.aganalytics.fi")*/ || window.location.origin.includes("dashboard.aganalytics.no")
    },

    isSwedishServer() {
      return this.isVGSServer || this.isPostNordServer
    },

    isVGSServer() {
      return window.location.origin.includes("vgs.aganalytics.se")
    },

    isFTZServer() {
      return window.location.origin.includes("ftz.auto-flows.com")
    },

    isPostNordServer() {
      return window.location.origin.includes("postnord.aganalytics.se")
    },

    isAutoFlowsServer() {
//      return true
      return window.location.origin.includes("dashboard.auto-flows.com")
    },

    isFTZOrAuto() {
      return this.isFTZServer || this.isAutoFlowsServer
    },

    isFinlandServer() {
      return window.location.origin.includes("dashboard.aganalytics.fi")
    },


    isSelectingUniverse() {
      return this.userIdentity && (this.userIdentity.loginStatus===5 || _.has(this.$route.query, 'change'))
    },

    isSelectingUniverseByIndicators() {
      return this.userIdentity && (this.userIdentity.loginStatus===6 || _.has(this.$route.query, 'change'))
    },

    isChangingUniverse() {
      return _.has(this.$route.query, 'change')
    },

    isSelectingActAsUniverseUser() {
      return this.userIdentity && (this.userIdentity.loginStatus===7)
    },

  },

  created () {
    // https://github.com/cmp-cc/vue-cookies#readme
    const c = this.$cookies.get("sessionId")

    if(c && !this.isChangingUniverse) {
//      console.log("Auth cookie:" + c)
      this.cookieLogin = true

      AuthService.authenticateCookie(c).then((response) => {
        const uid = response.data
        // console.log("Response from cookie check")
        // console.log(response.data)

        if( uid.loginStatus === 0) {
          this.$store.commit('login', uid)
        } else {
          this.$cookies.remove("sessionId")
          this.cookieLogin = false
        }
      })

    }
  },



  methods: {
    onLogin: function () {
      if( this.valid ) {
        this.showWaitScreen = true;

        let surveyName = this.$route.query.survey ? this.$route.query.survey : null

        this.$store.dispatch('authenticateUserPassword', {
          username: this.form.username,
          password: this.form.password,
          remember: this.form.remember,
          universe: null,
          surveyName: surveyName
        })
//        console.log('dispatched auth: ' + this.form.username)
      }
    },

    onUniverseSelect: function (universeMetaInterview) {
      this.showWaitScreen = true;

      if(_.has(this.$route.query, 'change')) {
        this.$store.dispatch('changeUniverse', universeMetaInterview.universe)
      } else {
        this.$store.dispatch('authenticateUserPassword', { username: this.form.username, password: this.form.password, remember: this.form.remember, universe: universeMetaInterview.universe, surveyName: universeMetaInterview.metainterview })
      }

    },

    onSelectedActAsUniverse: function (actAsUniverse) {

      this.actAsUsers = [ actAsUniverse.adminUser ]
      this.actAsSelectedUser = actAsUniverse.adminUser

      UsersService.fetchUniverseUsersData(this.getAuthToken, actAsUniverse.universeName).then((response) => {
        this.actAsUsers = response.data
      })

    },

    onSelectedActAsUser: function () {
      // console.log("onSelectedActAsUser")

      this.showWaitScreen = true;

      this.$store.dispatch('authenticateUserPassword',
          { actAsUser: this.actAsSelectedUser, actAsUniverse: this.actAsSelectedUniverse.universeName, username: this.form.username, password: this.form.password, remember: this.form.remember, universe: null, surveyName: "dummy" })
    },


    onForgotClicked() {
      PageNavigator.navigateToForgotPage(this)
    },

  },

  watch: {
    userIdentity: function (newUserId) {
      this.showWaitScreen = false;

      if (newUserId.loginStatus === 0) {
        if(this.forwardToVGSServer && this.isProductionServer) {
          PageNavigator.navigateToForwardVGSPage(this)
        } else if(!this.isFinlandServer && this.loginToFinnishUniverse) {
          PageNavigator.navigateToForwardFinlandPage(this)
        } else if(this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          if(newUserId.startSurvey) {
            this.$store.commit('selectSurvey', newUserId.startSurvey)
          }
          PageNavigator.navigateToDefaultPage(this, this.activeSurvey, this.getFilteredDrillPath)
        }

      } else if(newUserId.loginStatus === 5) {
        // console.log('Multi unis:' + newUserId.universes)
      }
    }
  }

}
</script>

<style scoped>
div.loginButtonPanel {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 1em;
}

div.forgotSignupPanel {
  display: flex;
  justify-content: space-between;
}

div.forgotSignupPanel a {
  color: #888888;
}

div.logocol {
  display: flex;
  justify-content: center;
}

div.mainTitle {
  justify-content: center;
  /*font-size: 30px;*/
  font-size: calc((30/16)*1rem);

}

div.subTitle {
  text-align: center;
  /*font-size: 18px;*/
  font-size: calc((18/16)*1rem);

}

div.footerRow {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: center;
  color: #888888
}

a.frontLink {
  margin-left: 6px;
  color: #888888;
  text-decoration: none;
}

div.waitContainer {
  display: flex;
  justify-content: center;
}

</style>

