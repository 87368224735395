<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-btn @click="onClick" icon v-on="on">

                <v-badge v-if="numberOpenAlerts>0" :content="numberOpenAlerts" color="#BE0000" offset-x="11" offset-y="20">
                    <v-icon size="26">mdi-alert-outline</v-icon>
                </v-badge>
                <v-icon v-else size="26">mdi-alert-outline</v-icon>

            </v-btn>
        </template>
        <span>{{'ALERTLIST' | i18n}}</span>
    </v-tooltip>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import PageNavigator from "../pagenavigator";

    export default {
        name: 'AlertNotifierComponent',

        computed: {
            ...mapState(['numberOpenAlerts']),
            ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'isAlertFilterDefined', 'getAlertFilteredDrillPath']),
        },

        methods: {
            onClick() {
                let drillpath = this.isAlertFilterDefined ? this.getAlertFilteredDrillPath : this.getFilteredDrillPath
                PageNavigator.navigateToAlertsPage(this, this.getActiveSurvey.extName, drillpath)
            }
        }

    }
</script>