<template>

  <v-container fluid pa-0>
    <SampleEditorDialog ref="sampleEditor"/>

    <v-row class="pa-3">
      <v-col>
        <RankingTable v-if='tableData.headers'
                      v-on:view-interview="onViewInterview"
                      v-on:view-sample="onViewSample"
                      v-on:drill-to="onDrillTo"
                      v-on:request-data="onRequestData"
                      :tableheaders="tableData.headers"
                      :tabledata="tableData.rows"
                      :tablefooter="tableData.footer"
                      :rankingVar="tableData.rankingVar"
                      :leaf-rows="tableData.leafRows"
                      :total-rows="tableData.totalNumberRows"
                      :loading="tableData.loading"
                      :table-options="tableData.tableOptions"/>

        <div v-else class="waitContainer">
          <v-progress-circular
              :size="100"
              :width="4"
              color="#0000c8"
              indeterminate
          />
        </div>


      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'
import RankingTable from "../../../ranking/RankingTable";
import ResultsService from "../../../../services/ResultsService";
import PageNavigator from "../../../../pagenavigator";
import SampleEditorDialog from "@/components/SampleEditorDialog";

export default {
  name: 'RankingTableComponent',

  components: {
    SampleEditorDialog,
    RankingTable
  },

  props: {
    componentData: Object
  },

  data: () => ({
    tableData: {
      headers: null,
      rows: null,
      footer: null,
      rankingVar: null,
      leafRows: null,
      totalNumberRows: null,
      loading: false,
      tableOptions: {
        page: 1,
        sortBy: [],
        sortDesc: []
      }
    },
    canViewCompletedSamples: false,
    dataRequestDTO: null
  }),


  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'rankingList', 'searchTerm', 'filterTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey', 'getFilterTerm']),

    page: function () {
      return _.find(this.activeSurvey.pages, { extname: 'rankings' })
    },

    enableRankingListReport: function() {
      return this.activeSurvey.enableRankingListReport /*&& this.tableData && !this.tableData.leafRows*/
    },

    enableSampleEditor() {
      return this.componentData.config.sampleEditor
    }


  },


  methods: {
    initialFetchData() {
      this.tableData.loading = true

      ResultsService.fetchNewRankingData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath, this.getFilterTerm).then((response) => {
        const rankingData = response.data.rankingData
        this.tableData.headers = rankingData.headers
        this.tableData.rows = rankingData.rows
        this.tableData.footer = rankingData.footer
        this.tableData.rankingVar = rankingData.rankingVar
        this.tableData.leafRows = rankingData.leafRows
        this.tableData.totalNumberRows = rankingData.totalNumberRows

        this.tableData.loading = false

        this.canViewCompletedSamples = response.data.viewCompletedSamples
      })

    },

    onRequestData: function (dataRequestDTO) {
      this.dataRequestDTO = dataRequestDTO

      let filterTerm = dataRequestDTO.filter

      if (this.tableData.leafRows) {
        if (!filterTerm) {
          filterTerm = this.getFilterTerm
        }

        this.tableData.loading = true

        this.tableData.tableOptions.page = 1 + (dataRequestDTO.rowFrom / 50)
        this.tableData.tableOptions.sortBy = [dataRequestDTO.sortVar]
        this.tableData.tableOptions.sortDesc = [dataRequestDTO.sortDesc]

        ResultsService.fetchNewRankingDataPaged(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath, dataRequestDTO.rowFrom, dataRequestDTO.rowTo, dataRequestDTO.sortVar, dataRequestDTO.sortDesc, filterTerm).then((response) => {
          const rankingData = response.data.rankingData
          this.tableData.rows = rankingData.rows
          this.tableData.totalNumberRows = rankingData.totalNumberRows
          this.tableData.loading = false
        })
      } else {
        this.$store.commit('setFilterTerm', filterTerm)

        this.initialFetchData()
      }

    },

    loadSegmentedPath() {
      ResultsService.fetchSegmentedDrillPath(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath).then((response) => {
        this.$store.commit('setSegmentedDrillPath', response.data)
        this.$store.commit('setNumberOpenAlerts', response.data.nOpenAlerts)
      })
    },

    replaceCurrentRoute() {
      // console.log("replaceCurrentRoute")

      if (this.$route.params.pageId) {
        this.$router.replace({
          name: 'page',
          params: {
            pageId: this.$route.params.pageId,
            surveyExtName: this.activeSurvey.extName,
            drillPath: this.getFilteredDrillPath
          },
          query: {
            page: this.tableData.tableOptions.page,
            sortBy: this.tableData.tableOptions.sortBy,
            sortDesc: this.tableData.tableOptions.sortDesc
          }
        })

      } else {
        this.$router.replace({
          name: 'rankings',
          params: {
            surveyExtName: this.activeSurvey.extName,
            drillPath: this.getFilteredDrillPath
          },
          query: {
            page: this.tableData.tableOptions.page,
            sortBy: this.tableData.tableOptions.sortBy,
            sortDesc: this.tableData.tableOptions.sortDesc
          }
        })

        // console.log("Calling loadSegmentedPath")
        this.loadSegmentedPath()

      }

    },

    onDrillToNamedPath: function (namedPath) {
      if (namedPath.entityId) {
        if (namedPath.isCompleted) {
          this.replaceCurrentRoute()
          PageNavigator.navigateToInterviewPage(this.$router, this.activeSurvey.extName, namedPath.entityId)
        } else {
          this.replaceCurrentRoute()
          PageNavigator.navigateToSamplePage(this.$router, this.activeSurvey.extName, namedPath.entityId)
        }

      } else {
        this.$store.commit('setDrillPath', namedPath.path)
      }
    },

    onDrillTo: function (drillPath) {
      // console.log("onDrillTo - setDrillPath: " + drillPath)
      //
      // console.log("before - getFiltered: " + this.getFilteredDrillPath)

      this.$store.commit('setDrillPath', drillPath)

      // console.log("after - getFiltered: " + this.getFilteredDrillPath)

      this.replaceCurrentRoute()
    },

    onViewInterview: function (interviewId) {
      if (this.enableSampleEditor) {
        this.openSampleEditor(interviewId)
      } else {
        this.replaceCurrentRoute()
        this.$router.push({
          name: 'interview',
          params: {surveyExtName: this.activeSurvey.extName, interviewId: interviewId}
        })
      }

    },

    onViewSample: function (sampleId) {
      if (this.enableSampleEditor) {
        this.openSampleEditor(sampleId)
      } else {
        let pageId = this.$route.params.pageId ? this.$route.params.pageId : 'ranking'
        this.replaceCurrentRoute()
        this.$router.push({
          name: 'sample',
          params: {surveyExtName: this.activeSurvey.extName, sampleId: sampleId, fromPage: pageId}
        })
      }
    },

    async openSampleEditor(sampleId) {
      if (await this.$refs.sampleEditor.open(this.componentData.config.id, sampleId)) {
        this.onRequestData(this.dataRequestDTO)
      } else {
        this.onRequestData(this.dataRequestDTO)
      }
    },
  },

  watch: {
    $route: {
      handler() {
        let drillPath = this.$route.params.drillPath
        this.$store.commit('setFilteredDrillPath', drillPath)
        this.$store.commit('setFilterTerm', "")
        this.$store.commit("setSearchSuggestions", [])

        const pageNumber = (this.$route.query.page) ? this.$route.query.page : 1
        const sortBy = (this.$route.query.sortBy) ? this.$route.query.sortBy : "none"
        const sortDesc = (this.$route.query.sortDesc) ? (this.$route.query.sortDesc === 'true') : true

        this.tableData.tableOptions.page = pageNumber
        this.tableData.tableOptions.sortBy = [sortBy]
        this.tableData.tableOptions.sortDesc = [sortDesc]

        this.initialFetchData();
      },
      immediate: true
    },

    getFilteredDrillPath: function (newPath, oldPath) {
      if (newPath && (newPath !== oldPath)) {
        this.$router.push({ name: 'rankings', params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath } })
        this.$store.commit('setFilterTerm', "")
        this.$store.commit("setSearchSuggestions", [])
      }
    },

    filterTerm: function (newTerm, oldTerm) {
      if (newTerm !== oldTerm) {
        this.initialFetchData();
      }
    },

    searchTerm: function (newTerm, oldTerm) {
      if (newTerm && (newTerm !== oldTerm)) {

        ResultsService.fetchNewRankingSuggestions(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath, newTerm).then((response) => {
          const suggestionsDTO = response.data
          this.$store.commit("setSearchSuggestions", suggestionsDTO.suggestions)
        })
      }
    }

  },

}
</script>
<style scoped>

div.waitContainer {
  display: flex;
  justify-content: center;
}


</style>