<template>
  <v-container fluid pa-0>
    <ConfirmDialog ref="confirm" />

    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathComponent @path-selected="onDrillToNamedPath" :show-filter="true" :show-download="true"/>
      </v-col>
    </v-row>

    <v-row class="elevation-2 filterRow">
      <v-col class="pt-0 pb-0">
        <FilterBarComponent v-if="activeSurvey && activeSurvey.timeFilterSet && activeSurvey.standardFilterSet"  :filter-sets="[activeSurvey.standardFilterSet, activeSurvey.timeFilterSet]" :show-info="true"/>
      </v-col>
    </v-row>

    <v-row v-if="page && page.components">
      <v-col>
        <PageComponent v-for="(comp, index) in page.components" :component-data="comp" :key="index"/>
      </v-col>
    </v-row>

    <v-row class="pa-3">
      <v-col>
        <RankingTable v-if='tableData.headers'
                      v-on:view-sample="onViewSample"
                      v-on:view-interview="onViewInterview"
                      v-on:request-data="onRequestData"
                      :tableheaders="tableData.headers"
                      :tabledata="tableData.rows"
                      :tablefooter="tableData.footer"
                      :leaf-rows="true"
                      :total-rows="tableData.totalNumberRows"
                      :loading="tableData.loading"
                      :table-options="tableData.tableOptions"/>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="1">
        <v-btn v-if="isUserRoot" @click="onDeleteAll">delete all</v-btn>
      </v-col>
    </v-row>

  </v-container>

</template>


<script>
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'
import FilterBarComponent from "../../components/FilterBarComponent";
import RankingTable from "../ranking/RankingTable";
import DrillPathComponent from "../../components/DrillPathComponent";
import SamplesService from "../../services/SamplesService";
import PageComponent from "../pages/PageComponent";
import PageNavigator from "@/pagenavigator";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: 'samplelist',

  components: {
    ConfirmDialog,
    DrillPathComponent, FilterBarComponent, RankingTable, PageComponent
  },

  data: () => ({
    tableData: {
      headers: null,
      rows: null,
      footer: null,
      rankingVar: null,
      leafRows: null,
      totalNumberRows: null,
      loading: false,
      tableOptions: {
        page: 1,
        sortBy: [],
        sortDesc: []
      }

    },
    canViewCompletedSamples: false,
  }),


  methods: {
    async onDeleteAll() {
      if (await this.$refs.confirm.open("Confirm", "PERMANANTLY delete all selected samples from database?")) {
        SamplesService.deleteSamplesListData(this.getAuthToken, this.activeSurvey.extName, "*", this.getFilteredDrillPath, 0, 50, "eventdate", true, this.filterTerm).then(() => {
          this.initialFetchData()
        })

      }
    },

    onDrillToNamedPath: function (namedPath) {
      if(namedPath.entityId) {
        PageNavigator.navigateToSamplePage(this, this.activeSurvey.extName, "samples", namedPath.entityId)
        //this.$router.push({ name: 'sample', params: { surveyExtName: this.activeSurvey.extName, sampleId: namedPath.entityId } })
      } else {
        this.$store.commit('setDrillPath', namedPath.path)
      }
    },

    replaceCurrentRoute() {
      this.$router.replace( {
        name: 'samples',
        params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath },
        query: { page: this.tableData.tableOptions.page, sortBy: this.tableData.tableOptions.sortBy, sortDesc: this.tableData.tableOptions.sortDesc }
      })
    },

    onViewSample: function (interviewId) {
      this.replaceCurrentRoute()
      PageNavigator.navigateToSamplePage(this, this.activeSurvey.extName, "samples", interviewId)
//                this.$router.push({ name: 'sample', params: { surveyExtName: this.activeSurvey.extName, sampleId: interviewId } })
    },

    onViewInterview: function (interviewId) {
      this.replaceCurrentRoute()

      if( this.canViewCompletedSamples) {
        PageNavigator.navigateToSamplePage(this, this.activeSurvey.extName, "samples", interviewId)
//                  this.$router.push({ name: 'sample', params: { surveyExtName: this.activeSurvey.extName, sampleId: interviewId } })
      } else {
        this.$router.push({ name: 'interview', params: { surveyExtName: this.activeSurvey.extName, interviewId: interviewId } })
      }
    },


    initialFetchData() {

      this.tableData.loading = true

      SamplesService.fetchSamplesListData(this.getAuthToken, this.activeSurvey.extName, "*", this.getFilteredDrillPath, 0, 50, "eventdate", true, this.filterTerm).then((response) => {
        this.$store.commit('setSegmentedDrillPath', response.data.segmentedDrillPath)

        const sampleData = response.data.rankingData

        this.tableData.headers = sampleData.headers
        this.tableData.rows = sampleData.rows
        this.tableData.footer = sampleData.footer
        this.tableData.rankingVar = sampleData.rankingVar
        this.tableData.leafRows = sampleData.leafRows
        this.tableData.totalNumberRows = sampleData.totalNumberRows


        this.tableData.loading = false

        this.canViewCompletedSamples = response.data.viewCompletedSamples
      })

    },

    onRequestData: function (dataRequestDTO) {

      let filterTerm = dataRequestDTO.filter

      if(!filterTerm) {
        filterTerm = this.getFilterTerm
      }

      // console.log("Page: " + (dataRequestDTO.rowFrom/50))
      this.tableData.tableOptions.page = 1+(dataRequestDTO.rowFrom/50)
      this.tableData.tableOptions.sortBy = [dataRequestDTO.sortVar]
      this.tableData.tableOptions.sortDesc = [ dataRequestDTO.sortDesc ]

      SamplesService.fetchSamplesListData(this.getAuthToken, this.activeSurvey.extName, "*", this.getFilteredDrillPath, dataRequestDTO.rowFrom, dataRequestDTO.rowTo, dataRequestDTO.sortVar, dataRequestDTO.sortDesc, filterTerm).then((response) => {
        this.$store.commit('setSegmentedDrillPath', response.data.segmentedDrillPath)
        const sampleData = response.data.rankingData

        this.tableData.rows = sampleData.rows
        this.tableData.loading = false

        this.canViewCompletedSamples = response.data.viewCompletedSamples
      })

    },

  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'searchTerm', 'filterTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    page: function () {
      return _.find(this.activeSurvey.pages, { extname: 'samples' })
    }

  },

  watch: {
    $route: {
      handler() {
        let surveyExtName = this.$route.params.surveyExtName
        if(surveyExtName !== this.activeSurvey.extName) {
          this.$store.commit('selectSurvey', surveyExtName)
        }

        let drillPath = this.$route.params.drillPath
        this.$store.commit('setFilteredDrillPath', drillPath)
        this.$store.commit('setFilterTerm', "")
        this.$store.commit("setSearchSuggestions", [])

        const pageNumber = (this.$route.query.page) ? this.$route.query.page : 1
        const sortBy = (this.$route.query.sortBy) ? this.$route.query.sortBy : "eventdate"
        const sortDesc = (this.$route.query.sortDesc) ? (this.$route.query.sortDesc === 'true') : false

        this.tableData.tableOptions.page = pageNumber
        this.tableData.tableOptions.sortBy = [sortBy]
        this.tableData.tableOptions.sortDesc = [sortDesc]

        this.initialFetchData();
      },
      immediate: true
    },

    getFilteredDrillPath: function (newPath, oldPath) {
      if (newPath && (newPath !== oldPath)) {
        this.$store.commit('setFilterTerm', "")
        this.$router.push({ name: 'samples', params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath } })
      } else {
//          console.log("They were the same: " + this.getFilteredDrillPath)
      }
    },

    filterTerm: function (newTerm, oldTerm) {
      if (newTerm !== oldTerm) {
        this.initialFetchData();
      }
    },

    searchTerm: function (newTerm, oldTerm) {
      if (newTerm && (newTerm !== oldTerm)) {
        SamplesService.fetchSampleListSuggestions(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath, newTerm).then((response) => {
          const suggestionsDTO = response.data
          this.$store.commit("setSearchSuggestions", suggestionsDTO.suggestions)
        })
      }
    }



  }





}
</script>
