<template>
  <v-container fluid pa-3>
    <v-dialog v-if="campaignInfo" v-model="infoDialog" :max-width="600" style="{ z-index: 200 }">
      <MailCampaignInfo :campaign-information="campaignInfo" :key="templateName"/>
    </v-dialog>

    <ConfirmDialog ref="confirm" />

  <v-row v-if="campaignDetails">
    <v-col>

      <v-data-table
          :search = "search"
          :headers="headers"
          :server-items-length="campaignDetails.numberOfTrackingRows"
          :items="rows"
          @update:options="onOptionsChanged"
          @click:row="onRowClicked"
          :options.sync="options"
          :loading="loading"
          item-key="id"
          class="elevation-2"
          :items-per-page="50"
          must-sort
          :sort-by.sync="sortBy"
          show-select
          v-model="selectedRows"
          :single-select="false" >

        <template v-slot:top>
          <v-row class="align-center">
            <v-col cols="6">
              <v-text-field v-model="search" label="Filter" class="mx-4" @change="onFilterEnter"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-btn :disabled="!canBeStopped" @click="onStopCampaign" class="mr-4" >{{'MAIL_CAMPAIGN_STOP' | i18n}}</v-btn>
              <v-btn @click="onDownloadClicked">{{'DOWNLOAD' | i18n}}</v-btn>
            </v-col>
          </v-row>

          <v-row v-if="selectedRows.length>0">
            <v-col >
              <v-btn @click="onSelectedMail" :disabled="!campaignDetails.mailTemplate"  class="ml-4">{{'MAIL' | i18n}}</v-btn>
              <v-btn @click="onSelectedDelete" class="ml-4">{{'DELETE' | i18n}}</v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.viewTemplate="{ item }">
          <v-dialog width="auto ">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="!selectedTemplate"  class="viewButton" text v-bind="attrs" v-on="on">view</v-btn>
            </template>
            <RenderedMail :campaign-id="campaignId" :template="selectedTemplate" :tracking-id="item.id"  />
          </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" v-if="campaignDetails.mailTemplate" @click="onMailItem(item)">mdi-email</v-icon>
          <v-icon small @click="onDeleteItem(item)">mdi-delete</v-icon>
        </template>

      </v-data-table>

    </v-col>

  </v-row>


  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import PageNavigator from "../../pagenavigator";
import MailsService from "@/services/MailsService";
import RenderedMail from "@/views/mailcampaigns/RenderedMail";
import ConfirmDialog from "@/components/ConfirmDialog";
import MailCampaignInfo from "@/views/mailcampaigns/MailCampaignInfo";
import ReportsService from "@/services/ReportsService";

export default {
  name: 'MailCampaignDetails',

  components: {
    MailCampaignInfo,
    ConfirmDialog,
    RenderedMail
  },

  props: {
    campaignIdParam: Number,
    showInfoDialog: Boolean
  },

  created: function () {
    this.fetchCampaign()
  },

  data: () => ({
    campaignDetails: null,
    infoDialog: false,

    isValid: false,

    mailTemplates: [],
    selectedTemplate: null,

    options: {},
    dialog: false,

    isLoadingTemplates: false,

    search: '',

    loading: false,

    rows: [],

    sortBy: 'id',

    selectedRows: []

  }),


  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    headers() {
      return [
        { text: this.$t('NAME'), align: 'left', value: 'respondent' },
        { text: this.$t('EMAIL'), align: 'left', value: 'email' },
        { text: this.$t('STATE_FILTER'), align: 'left', value: 'status' },
        { text: this.$t('MAIL_CAMPAIGN_PREVIEW'), align: 'left', value: 'viewTemplate' },
        { text: this.$t('VIEWS'), align: 'left', value: 'openCount' },
        { text: this.$t('CLICKS'), align: 'left', value: 'clickCount' },
        { text: this.$t('ACTIONS'), value: 'actions', sortable: false },
      ];
    },

    campaignId() {
      return this.campaignIdParam ? this.campaignIdParam : this.$route.query.campaignId
    },

    canBeStarted() {
      return this.campaignDetails.canStart
    },

    canBeStopped() {
      return this.campaignDetails.canStop
    },

    mandatoryRules() {
      return  [
        value => !!value || this.$t('VALIDATE_ERROR_MANDATORY'),
      ]
    },

    templateName() {
      return this.campaignDetails ? this.campaignDetails.mailTemplate : "?"
    },

    campaignInfo() {

        return this.campaignDetails ?
            {
              campaignName: this.campaignDetails.title,
              subject: "sub",
              templateName: this.campaignDetails.mailTemplate,
              filters: this.campaignDetails.campaignFilters
            } : null

      }

  },


  methods: {
    fetchCampaign() {
      MailsService.fetchMailCampaignData(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((response) => {
        this.campaignDetails = response.data
        this.selectedTemplate = this.campaignDetails.mailTemplate
        this.mailTemplates = [this.selectedTemplate]
      }),

      this.isLoadingTemplates = true
      MailsService.fetchMailTemplatesListDataNoLabel(this.getAuthToken, this.activeSurvey.extName).then((response) => {
        this.mailTemplates = response.data
        this.isLoadingTemplates = false
      })

    },


    fetchMailCampaignData() {
      MailsService.fetchMailCampaignData(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((response) => {
        this.campaignDetails = response.data
      })
    },

    onUpdateClicked() {
      this.campaignDetails.mailTemplate = this.selectedTemplate

      MailsService.updateMailCampaignData(this.getAuthToken, this.activeSurvey.extName, this.campaignId, this.campaignDetails).then((/*response*/) => {
        this.fetchMailCampaignData();
      })

    },

    onRemoveDuplicates() {
      MailsService.deleteDuplicatesFromMailCampaign(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((response) => {
        const nRemoved = response.data.nRemoved
        const nLeft = response.data.nLeft

        this.fetchMailCampaignData();
        this.$refs.confirm.open("Confirm", nRemoved + " dubletter blandt modtagere fjernet Der er " + nLeft + " unikke modtagere tilbage")
      })

    },

    async onStartCampaign() {
      if ( await this.$refs.confirm.open("Confirm", "Start campaign?")) {
        MailsService.startMailCampaign(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((/*response*/) => {
          this.fetchMailCampaignData()
          this.onOptionsChanged(this.options)
        })
      }
    },

    goToList() {
      const fromPage = this.$route.query.fromPage

      if(fromPage) {
        PageNavigator.navigateToPage(this, fromPage, this.activeSurvey.extName,  this.getFilteredDrillPath)
      } else {
        PageNavigator.navigateToDefaultPage(this, this.activeSurvey)
      }
    },

    onRowClicked(row) {
      PageNavigator.navigateToInterviewPage(this, this.activeSurvey.extName, row.sampleId)
    },

    onOptionsChanged(options) {
      const pageNumber = options.page
      const pageSize = options.itemsPerPage

      const from = (pageNumber-1)*pageSize
      const to = from + pageSize

      const sortBy = options.sortBy[0]
      const sortDesc = options.sortDesc[0]

      MailsService.fetchMailTrackingRows(this.getAuthToken, this.activeSurvey.extName, this.campaignId, from, to, sortBy, sortDesc, this.search).then((response) => {
        this.rows = response.data
      })
    },

    onFilterEnter() {
      this.onOptionsChanged(this.options)
    },

    onSelectedMail() {
      const trackingIds = this.selectedRows.map( row => row.id)
      MailsService.sendMails(this.getAuthToken, this.activeSurvey.extName, this.campaignId, trackingIds).then((/*response*/) => {
        this.fetchMailCampaignData();
        this.onOptionsChanged(this.options)
      })
    },

    onMailItem(row) {
      MailsService.sendMails(this.getAuthToken, this.activeSurvey.extName, this.campaignId, [row.id]).then((/*response*/) => {
        this.fetchMailCampaignData();
        this.onOptionsChanged(this.options)
      })

    },

    onSelectedDelete() {
      const trackingIds = this.selectedRows.map( row => row.id)
      MailsService.deleteMailTracking(this.getAuthToken, this.activeSurvey.extName, this.campaignId, trackingIds).then((/*response*/) => {
        this.fetchMailCampaignData();
        this.onOptionsChanged(this.options)
      })
    },

    onDeleteItem(row) {
      MailsService.deleteMailTracking(this.getAuthToken, this.activeSurvey.extName, this.campaignId, [row.id]).then((/*response*/) => {
        this.fetchMailCampaignData();
        this.onOptionsChanged(this.options)
      })
    },

    onCancelClicked() {
      this.goToList()
    },

    async onStopCampaign() {
      if ( await this.$refs.confirm.open("Confirm", "Stop campaign?")) {
        MailsService.stopMailCampaign(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then(() => {
          this.fetchMailCampaignData()
        })
      }
    },

    async onDeleteClicked() {
      if ( await this.$refs.confirm.open("Confirm", "Delete campaign?")) {
        MailsService.deleteMailCampaign(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then(() => {
          this.goToList()
        })
      }
    },

    onDownloadClicked() {
      window.open(ReportsService.mailCampaignReportDownloadURL(this.getAuthToken, this.campaignId), '_blank')
    }


  },

  watch: {
    showInfoDialog: function() { // watch it
      this.infoDialog = true
    }
  }


}
</script>

<style scoped>

.statsLabel {
  font-weight: bold;
  margin-right: 4px;
}

.viewButton {
  text-transform: unset !important;
  font-weight: normal !important;
}

</style>
