import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import AnalyticsService from "./services/AnalyticsService";
import VueAnalytics from 'vue-analytics'
import './assets/css/main.css';

import VueCookies from 'vue-cookies'
import Highcharts from 'highcharts';
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

Highcharts.setOptions({
  exporting: {
    enabled: true,
    buttons: {
      contextBotton: {
        enabled: true,
        menuItems: ["printChart", "separator", "downloadPNG", "downloadPDF"]
      }
    }
  }
})
Vue.use(VueCookies)

Vue.use(VueAnalytics, {
  id: AnalyticsService.getTrackingId()
})

Vue.config.productionTip = false

Vue.filter('dateFilter', function (indate) {
  if (!indate) return 'N/A'
  return indate.day + '/' + indate.month + '-' + indate.year
})

Vue.filter('i18n', function (intext) {
  return store.getters.getTranslation(intext)
})

Vue.prototype.$t = function (key) {
  return store.getters.getTranslation(key)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.config.devtools = true;